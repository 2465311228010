/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOrganisationDetailsResponseContent
 */
export interface GetOrganisationDetailsResponseContent {
    /**
     * 
     * @type {string}
     * @memberof GetOrganisationDetailsResponseContent
     */
    organisationName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganisationDetailsResponseContent
     */
    organisationDescription?: string;
}


/**
 * Check if a given object implements the GetOrganisationDetailsResponseContent interface.
 */
export function instanceOfGetOrganisationDetailsResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetOrganisationDetailsResponseContentFromJSON(json: any): GetOrganisationDetailsResponseContent {
    return GetOrganisationDetailsResponseContentFromJSONTyped(json, false);
}

export function GetOrganisationDetailsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrganisationDetailsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisationName': !exists(json, 'organisationName') ? undefined : json['organisationName'],
        'organisationDescription': !exists(json, 'organisationDescription') ? undefined : json['organisationDescription'],
    };
}

export function GetOrganisationDetailsResponseContentToJSON(value?: GetOrganisationDetailsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisationName': value.organisationName,
        'organisationDescription': value.organisationDescription,
    };
}

