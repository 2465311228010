/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An error due to the client not being authorized to access the resource
 * @export
 * @interface NotAuthorizedErrorResponseContent
 */
export interface NotAuthorizedErrorResponseContent {
    /**
     * Message with details about the error
     * @type {string}
     * @memberof NotAuthorizedErrorResponseContent
     */
    message: string;
}


/**
 * Check if a given object implements the NotAuthorizedErrorResponseContent interface.
 */
export function instanceOfNotAuthorizedErrorResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function NotAuthorizedErrorResponseContentFromJSON(json: any): NotAuthorizedErrorResponseContent {
    return NotAuthorizedErrorResponseContentFromJSONTyped(json, false);
}

export function NotAuthorizedErrorResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotAuthorizedErrorResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function NotAuthorizedErrorResponseContentToJSON(value?: NotAuthorizedErrorResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}

