/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileS3Location } from './FileS3Location';
import {
    FileS3LocationFromJSON,
    FileS3LocationFromJSONTyped,
    FileS3LocationToJSON,
} from './FileS3Location';

/**
 * 
 * @export
 * @interface QaCSVMetadata
 */
export interface QaCSVMetadata {
    /**
     * 
     * @type {FileS3Location}
     * @memberof QaCSVMetadata
     */
    fileS3Location?: FileS3Location;
    /**
     * 
     * @type {string}
     * @memberof QaCSVMetadata
     */
    status: string;
}


/**
 * Check if a given object implements the QaCSVMetadata interface.
 */
export function instanceOfQaCSVMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function QaCSVMetadataFromJSON(json: any): QaCSVMetadata {
    return QaCSVMetadataFromJSONTyped(json, false);
}

export function QaCSVMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): QaCSVMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileS3Location': !exists(json, 'fileS3Location') ? undefined : FileS3LocationFromJSON(json['fileS3Location']),
        'status': json['status'],
    };
}

export function QaCSVMetadataToJSON(value?: QaCSVMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileS3Location': FileS3LocationToJSON(value.fileS3Location),
        'status': value.status,
    };
}

