/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SynopsisEvaluationRating } from './SynopsisEvaluationRating';
import {
    SynopsisEvaluationRatingFromJSON,
    SynopsisEvaluationRatingFromJSONTyped,
    SynopsisEvaluationRatingToJSON,
} from './SynopsisEvaluationRating';

/**
 * 
 * @export
 * @interface UpdatePromptFeedbackRequestContent
 */
export interface UpdatePromptFeedbackRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePromptFeedbackRequestContent
     */
    questionId: string;
    /**
     * 
     * @type {SynopsisEvaluationRating}
     * @memberof UpdatePromptFeedbackRequestContent
     */
    rating?: SynopsisEvaluationRating;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromptFeedbackRequestContent
     */
    comment?: string;
}


/**
 * Check if a given object implements the UpdatePromptFeedbackRequestContent interface.
 */
export function instanceOfUpdatePromptFeedbackRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "questionId" in value;

    return isInstance;
}

export function UpdatePromptFeedbackRequestContentFromJSON(json: any): UpdatePromptFeedbackRequestContent {
    return UpdatePromptFeedbackRequestContentFromJSONTyped(json, false);
}

export function UpdatePromptFeedbackRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePromptFeedbackRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': json['questionId'],
        'rating': !exists(json, 'rating') ? undefined : SynopsisEvaluationRatingFromJSON(json['rating']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function UpdatePromptFeedbackRequestContentToJSON(value?: UpdatePromptFeedbackRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'rating': SynopsisEvaluationRatingToJSON(value.rating),
        'comment': value.comment,
    };
}

