/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActionType,
  BadRequestErrorResponseContent,
  ChatRequestContent,
  ChatResponseContent,
  CheckEndpointStatusResponseContent,
  CreateDataDialogPromptRequestContent,
  CreateDataDialogPromptResponseContent,
  CreatePromptRevisionRequestContent,
  CreatePromptRevisionResponseContent,
  CreateUpdateAgentRequestContent,
  CreateUpdateAgentResponseContent,
  DeleteAgentResponseContent,
  DeleteAgentsRequestContent,
  DeleteAgentsResponseContent,
  DeleteEndpointResponseContent,
  ExportQAEvaluationToCSVRequestContent,
  ExportQAEvaluationToCSVResponseContent,
  GetAgentResponseContent,
  GetAnalyticsAggregationScoresRequestContent,
  GetAnalyticsAggregationScoresResponseContent,
  GetAssessEnabledResponseContent,
  GetAudioPresignedURLResponseContent,
  GetBatchContactImportResponseContent,
  GetBedrockRegionResponseContent,
  GetConnectAccessUrlResponseContent,
  GetContactMetadataResponseContent,
  GetContactResponseContent,
  GetDataDialogPromptResponseContent,
  GetInferenceMethodResponseContent,
  GetOrganisationDetailsResponseContent,
  GetPromptAggregateAnalyticsWithFiltersRequestContent,
  GetPromptAggregateAnalyticsWithFiltersResponseContent,
  GetPromptRatingAggregationResponseContent,
  GetPromptReportsResponseContent,
  GetPromptRevisionResponseContent,
  GetPromptTrendAnalyticsWithFiltersRequestContent,
  GetPromptTrendAnalyticsWithFiltersResponseContent,
  GetPublishedPromptRevisionResponseContent,
  GetQAAnalyticsWithFiltersRequestContent,
  GetQAAnalyticsWithFiltersResponseContent,
  GetQaAggregationSectionScoreRequestContent,
  GetQaAggregationSectionScoreResponseContent,
  GetTranscriptResponseContent,
  InternalFailureErrorResponseContent,
  ListAgentsResponseContent,
  ListAnalyticsResponseContent,
  ListAnalyticsWithFiltersRequestContent,
  ListAnalyticsWithFiltersResponseContent,
  ListBatchContactImportResultsResponseContent,
  ListBatchContactImportsResponseContent,
  ListContactMetadataColumnsResponseContent,
  ListContactsByFiltersRequestContent,
  ListContactsByFiltersResponseContent,
  ListContactsMetadataResponseContent,
  ListContactsResponseContent,
  ListDataDialogPromptsResponseContent,
  ListOptionsForColumnResponseContent,
  ListPromptRevisionsResponseContent,
  ListPromptVisualisationsResponseContent,
  ListTranscriptsResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  PublishPromptRevisionResponseContent,
  QueryPromptRatingMetricsResponseContent,
  REGION,
  ReTryContactActionResponseContent,
  ReimportContactJobResponseContent,
  SetAssessEnabledResponseContent,
  SetBedrockRegionResponseContent,
  SetInferenceMethodResponseContent,
  SetOrganisationDetailsRequestContent,
  SetOrganisationDetailsResponseContent,
  StartBatchContactImportRequestContent,
  StartBatchContactImportResponseContent,
  StartEndpointResponseContent,
  TestPromptForContactRequestContent,
  TestPromptForContactResponseContent,
  UpdateContactSummaryEvaluationRequestContent,
  UpdateContactSummaryEvaluationResponseContent,
  UpdateDataDialogPromptRequestContent,
  UpdateDataDialogPromptResponseContent,
  UpdatePromptFeedbackRequestContent,
  UpdatePromptFeedbackResponseContent,
  UpdatePromptRevisionRequestContent,
  UpdatePromptRevisionResponseContent,
  UpdatePromptVisualisationsRequestContent,
  UpdatePromptVisualisationsResponseContent,
} from '../models';
import {
    ActionTypeFromJSON,
    ActionTypeToJSON,
    BadRequestErrorResponseContentFromJSON,
    BadRequestErrorResponseContentToJSON,
    ChatRequestContentFromJSON,
    ChatRequestContentToJSON,
    ChatResponseContentFromJSON,
    ChatResponseContentToJSON,
    CheckEndpointStatusResponseContentFromJSON,
    CheckEndpointStatusResponseContentToJSON,
    CreateDataDialogPromptRequestContentFromJSON,
    CreateDataDialogPromptRequestContentToJSON,
    CreateDataDialogPromptResponseContentFromJSON,
    CreateDataDialogPromptResponseContentToJSON,
    CreatePromptRevisionRequestContentFromJSON,
    CreatePromptRevisionRequestContentToJSON,
    CreatePromptRevisionResponseContentFromJSON,
    CreatePromptRevisionResponseContentToJSON,
    CreateUpdateAgentRequestContentFromJSON,
    CreateUpdateAgentRequestContentToJSON,
    CreateUpdateAgentResponseContentFromJSON,
    CreateUpdateAgentResponseContentToJSON,
    DeleteAgentResponseContentFromJSON,
    DeleteAgentResponseContentToJSON,
    DeleteAgentsRequestContentFromJSON,
    DeleteAgentsRequestContentToJSON,
    DeleteAgentsResponseContentFromJSON,
    DeleteAgentsResponseContentToJSON,
    DeleteEndpointResponseContentFromJSON,
    DeleteEndpointResponseContentToJSON,
    ExportQAEvaluationToCSVRequestContentFromJSON,
    ExportQAEvaluationToCSVRequestContentToJSON,
    ExportQAEvaluationToCSVResponseContentFromJSON,
    ExportQAEvaluationToCSVResponseContentToJSON,
    GetAgentResponseContentFromJSON,
    GetAgentResponseContentToJSON,
    GetAnalyticsAggregationScoresRequestContentFromJSON,
    GetAnalyticsAggregationScoresRequestContentToJSON,
    GetAnalyticsAggregationScoresResponseContentFromJSON,
    GetAnalyticsAggregationScoresResponseContentToJSON,
    GetAssessEnabledResponseContentFromJSON,
    GetAssessEnabledResponseContentToJSON,
    GetAudioPresignedURLResponseContentFromJSON,
    GetAudioPresignedURLResponseContentToJSON,
    GetBatchContactImportResponseContentFromJSON,
    GetBatchContactImportResponseContentToJSON,
    GetBedrockRegionResponseContentFromJSON,
    GetBedrockRegionResponseContentToJSON,
    GetConnectAccessUrlResponseContentFromJSON,
    GetConnectAccessUrlResponseContentToJSON,
    GetContactMetadataResponseContentFromJSON,
    GetContactMetadataResponseContentToJSON,
    GetContactResponseContentFromJSON,
    GetContactResponseContentToJSON,
    GetDataDialogPromptResponseContentFromJSON,
    GetDataDialogPromptResponseContentToJSON,
    GetInferenceMethodResponseContentFromJSON,
    GetInferenceMethodResponseContentToJSON,
    GetOrganisationDetailsResponseContentFromJSON,
    GetOrganisationDetailsResponseContentToJSON,
    GetPromptAggregateAnalyticsWithFiltersRequestContentFromJSON,
    GetPromptAggregateAnalyticsWithFiltersRequestContentToJSON,
    GetPromptAggregateAnalyticsWithFiltersResponseContentFromJSON,
    GetPromptAggregateAnalyticsWithFiltersResponseContentToJSON,
    GetPromptRatingAggregationResponseContentFromJSON,
    GetPromptRatingAggregationResponseContentToJSON,
    GetPromptReportsResponseContentFromJSON,
    GetPromptReportsResponseContentToJSON,
    GetPromptRevisionResponseContentFromJSON,
    GetPromptRevisionResponseContentToJSON,
    GetPromptTrendAnalyticsWithFiltersRequestContentFromJSON,
    GetPromptTrendAnalyticsWithFiltersRequestContentToJSON,
    GetPromptTrendAnalyticsWithFiltersResponseContentFromJSON,
    GetPromptTrendAnalyticsWithFiltersResponseContentToJSON,
    GetPublishedPromptRevisionResponseContentFromJSON,
    GetPublishedPromptRevisionResponseContentToJSON,
    GetQAAnalyticsWithFiltersRequestContentFromJSON,
    GetQAAnalyticsWithFiltersRequestContentToJSON,
    GetQAAnalyticsWithFiltersResponseContentFromJSON,
    GetQAAnalyticsWithFiltersResponseContentToJSON,
    GetQaAggregationSectionScoreRequestContentFromJSON,
    GetQaAggregationSectionScoreRequestContentToJSON,
    GetQaAggregationSectionScoreResponseContentFromJSON,
    GetQaAggregationSectionScoreResponseContentToJSON,
    GetTranscriptResponseContentFromJSON,
    GetTranscriptResponseContentToJSON,
    InternalFailureErrorResponseContentFromJSON,
    InternalFailureErrorResponseContentToJSON,
    ListAgentsResponseContentFromJSON,
    ListAgentsResponseContentToJSON,
    ListAnalyticsResponseContentFromJSON,
    ListAnalyticsResponseContentToJSON,
    ListAnalyticsWithFiltersRequestContentFromJSON,
    ListAnalyticsWithFiltersRequestContentToJSON,
    ListAnalyticsWithFiltersResponseContentFromJSON,
    ListAnalyticsWithFiltersResponseContentToJSON,
    ListBatchContactImportResultsResponseContentFromJSON,
    ListBatchContactImportResultsResponseContentToJSON,
    ListBatchContactImportsResponseContentFromJSON,
    ListBatchContactImportsResponseContentToJSON,
    ListContactMetadataColumnsResponseContentFromJSON,
    ListContactMetadataColumnsResponseContentToJSON,
    ListContactsByFiltersRequestContentFromJSON,
    ListContactsByFiltersRequestContentToJSON,
    ListContactsByFiltersResponseContentFromJSON,
    ListContactsByFiltersResponseContentToJSON,
    ListContactsMetadataResponseContentFromJSON,
    ListContactsMetadataResponseContentToJSON,
    ListContactsResponseContentFromJSON,
    ListContactsResponseContentToJSON,
    ListDataDialogPromptsResponseContentFromJSON,
    ListDataDialogPromptsResponseContentToJSON,
    ListOptionsForColumnResponseContentFromJSON,
    ListOptionsForColumnResponseContentToJSON,
    ListPromptRevisionsResponseContentFromJSON,
    ListPromptRevisionsResponseContentToJSON,
    ListPromptVisualisationsResponseContentFromJSON,
    ListPromptVisualisationsResponseContentToJSON,
    ListTranscriptsResponseContentFromJSON,
    ListTranscriptsResponseContentToJSON,
    NotAuthorizedErrorResponseContentFromJSON,
    NotAuthorizedErrorResponseContentToJSON,
    NotFoundErrorResponseContentFromJSON,
    NotFoundErrorResponseContentToJSON,
    PublishPromptRevisionResponseContentFromJSON,
    PublishPromptRevisionResponseContentToJSON,
    QueryPromptRatingMetricsResponseContentFromJSON,
    QueryPromptRatingMetricsResponseContentToJSON,
    REGIONFromJSON,
    REGIONToJSON,
    ReTryContactActionResponseContentFromJSON,
    ReTryContactActionResponseContentToJSON,
    ReimportContactJobResponseContentFromJSON,
    ReimportContactJobResponseContentToJSON,
    SetAssessEnabledResponseContentFromJSON,
    SetAssessEnabledResponseContentToJSON,
    SetBedrockRegionResponseContentFromJSON,
    SetBedrockRegionResponseContentToJSON,
    SetInferenceMethodResponseContentFromJSON,
    SetInferenceMethodResponseContentToJSON,
    SetOrganisationDetailsRequestContentFromJSON,
    SetOrganisationDetailsRequestContentToJSON,
    SetOrganisationDetailsResponseContentFromJSON,
    SetOrganisationDetailsResponseContentToJSON,
    StartBatchContactImportRequestContentFromJSON,
    StartBatchContactImportRequestContentToJSON,
    StartBatchContactImportResponseContentFromJSON,
    StartBatchContactImportResponseContentToJSON,
    StartEndpointResponseContentFromJSON,
    StartEndpointResponseContentToJSON,
    TestPromptForContactRequestContentFromJSON,
    TestPromptForContactRequestContentToJSON,
    TestPromptForContactResponseContentFromJSON,
    TestPromptForContactResponseContentToJSON,
    UpdateContactSummaryEvaluationRequestContentFromJSON,
    UpdateContactSummaryEvaluationRequestContentToJSON,
    UpdateContactSummaryEvaluationResponseContentFromJSON,
    UpdateContactSummaryEvaluationResponseContentToJSON,
    UpdateDataDialogPromptRequestContentFromJSON,
    UpdateDataDialogPromptRequestContentToJSON,
    UpdateDataDialogPromptResponseContentFromJSON,
    UpdateDataDialogPromptResponseContentToJSON,
    UpdatePromptFeedbackRequestContentFromJSON,
    UpdatePromptFeedbackRequestContentToJSON,
    UpdatePromptFeedbackResponseContentFromJSON,
    UpdatePromptFeedbackResponseContentToJSON,
    UpdatePromptRevisionRequestContentFromJSON,
    UpdatePromptRevisionRequestContentToJSON,
    UpdatePromptRevisionResponseContentFromJSON,
    UpdatePromptRevisionResponseContentToJSON,
    UpdatePromptVisualisationsRequestContentFromJSON,
    UpdatePromptVisualisationsRequestContentToJSON,
    UpdatePromptVisualisationsResponseContentFromJSON,
    UpdatePromptVisualisationsResponseContentToJSON,
} from '../models';

export interface ChatRequest {
    chatRequestContent: ChatRequestContent;
}

export interface CreateDataDialogPromptRequest {
    createDataDialogPromptRequestContent: CreateDataDialogPromptRequestContent;
}

export interface CreatePromptRevisionRequest {
    createPromptRevisionRequestContent: CreatePromptRevisionRequestContent;
}

export interface CreateUpdateAgentRequest {
    createUpdateAgentRequestContent: CreateUpdateAgentRequestContent;
    agentId?: string;
}

export interface DeleteAgentRequest {
    agentId: string;
}

export interface DeleteAgentsRequest {
    deleteAgentsRequestContent: DeleteAgentsRequestContent;
}

export interface ExportQAEvaluationToCSVRequest {
    exportQAEvaluationToCSVRequestContent: ExportQAEvaluationToCSVRequestContent;
}

export interface GetAgentRequest {
    agentId: string;
}

export interface GetAnalyticsAggregationScoresRequest {
    contactImportId: string;
    getAnalyticsAggregationScoresRequestContent?: GetAnalyticsAggregationScoresRequestContent;
}

export interface GetAudioPresignedURLRequest {
    contactId: string;
    contactImportId: string;
}

export interface GetBatchContactImportRequest {
    contactImportId: string;
}

export interface GetContactRequest {
    contactId: string;
    contactImportId?: string;
}

export interface GetContactMetadataRequest {
    contactId: string;
    contactImportId?: string;
}

export interface GetDataDialogPromptRequest {
    dataDialogPromptId: string;
}

export interface GetPromptAggregateAnalyticsWithFiltersRequest {
    contactImportId: string;
    getPromptAggregateAnalyticsWithFiltersRequestContent: GetPromptAggregateAnalyticsWithFiltersRequestContent;
}

export interface GetPromptRatingAggregationRequest {
    promptRevisionId: string;
}

export interface GetPromptReportsRequest {
    promptRevisionId: string;
    questionKey: string;
}

export interface GetPromptRevisionRequest {
    promptRevisionId: string;
}

export interface GetPromptTrendAnalyticsWithFiltersRequest {
    contactImportId: string;
    getPromptTrendAnalyticsWithFiltersRequestContent: GetPromptTrendAnalyticsWithFiltersRequestContent;
}

export interface GetQAAnalyticsWithFiltersRequest {
    contactImportId: string;
    getQAAnalyticsWithFiltersRequestContent?: GetQAAnalyticsWithFiltersRequestContent;
}

export interface GetQaAggregationSectionScoreRequest {
    contactImportId: string;
    getQaAggregationSectionScoreRequestContent?: GetQaAggregationSectionScoreRequestContent;
}

export interface GetTranscriptRequest {
    contactId: string;
    transcriptId: string;
}

export interface ListAgentsRequest {
    year: number;
    nextToken?: string;
    pageSize?: number;
    before?: number;
    after?: number;
}

export interface ListAnalyticsRequest {
    promptRevisionId: string;
    contactImportId: string;
    from?: string;
    to?: string;
}

export interface ListAnalyticsWithFiltersRequest {
    contactImportId: string;
    listAnalyticsWithFiltersRequestContent?: ListAnalyticsWithFiltersRequestContent;
}

export interface ListBatchContactImportResultsRequest {
    contactImportId: string;
    nextToken?: string;
    pageSize?: number;
}

export interface ListBatchContactImportsRequest {
    year: number;
    nextToken?: string;
    pageSize?: number;
    before?: number;
    after?: number;
}

export interface ListContactsRequest {
    year: number;
    nextToken?: string;
    pageSize?: number;
    before?: number;
    after?: number;
}

export interface ListContactsByFiltersRequest {
    nextToken?: string;
    pageSize?: number;
    listContactsByFiltersRequestContent?: ListContactsByFiltersRequestContent;
}

export interface ListContactsMetadataRequest {
    contactImportId: string;
    nextToken?: string;
    pageSize?: number;
}

export interface ListOptionsForColumnRequest {
    columnName: string;
    nextToken?: string;
    pageSize?: number;
    contactImportId?: string;
}

export interface ListPromptRevisionsRequest {
    year: number;
    nextToken?: string;
    pageSize?: number;
    before?: number;
    after?: number;
}

export interface ListPromptVisualisationsRequest {
    promptRevisionId: string;
}

export interface ListTranscriptsRequest {
    contactId: string;
    nextToken?: string;
    pageSize?: number;
    contactImportId?: string;
    latestFirst?: boolean;
}

export interface PublishPromptRevisionRequest {
    promptRevisionId: string;
}

export interface QueryPromptRatingMetricsRequest {
    promptRevisionId: string;
}

export interface ReTryContactActionRequest {
    contactIds: Array<string>;
    contactImportId: string;
    actionType: ActionType;
}

export interface ReimportContactJobRequest {
    contactImportId: string;
    actionType: ActionType;
}

export interface SetAssessEnabledRequest {
    isQAEnabled: boolean;
}

export interface SetBedrockRegionRequest {
    region: REGION;
}

export interface SetInferenceMethodRequest {
    method: string;
}

export interface SetOrganisationDetailsRequest {
    setOrganisationDetailsRequestContent: SetOrganisationDetailsRequestContent;
}

export interface StartBatchContactImportRequest {
    startBatchContactImportRequestContent: StartBatchContactImportRequestContent;
}

export interface TestPromptForContactRequest {
    testPromptForContactRequestContent: TestPromptForContactRequestContent;
}

export interface UpdateContactSummaryEvaluationRequest {
    contactId: string;
    updateContactSummaryEvaluationRequestContent: UpdateContactSummaryEvaluationRequestContent;
    contactImportId?: string;
}

export interface UpdateDataDialogPromptRequest {
    dataDialogPromptId: string;
    updateDataDialogPromptRequestContent: UpdateDataDialogPromptRequestContent;
}

export interface UpdatePromptFeedbackRequest {
    contactId: string;
    updatePromptFeedbackRequestContent: UpdatePromptFeedbackRequestContent;
    contactImportId?: string;
}

export interface UpdatePromptRevisionRequest {
    promptRevisionId: string;
    updatePromptRevisionRequestContent: UpdatePromptRevisionRequestContent;
}

export interface UpdatePromptVisualisationsRequest {
    promptRevisionId: string;
    updatePromptVisualisationsRequestContent: UpdatePromptVisualisationsRequestContent;
}

/**
 * 
 */
export class AgentAssistApi extends runtime.BaseAPI {

    /**
     */
    async chatRaw(requestParameters: ChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatResponseContent>> {
        if (requestParameters.chatRequestContent === null || requestParameters.chatRequestContent === undefined) {
            throw new runtime.RequiredError('chatRequestContent','Required parameter requestParameters.chatRequestContent was null or undefined when calling chat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/chat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatRequestContentToJSON(requestParameters.chatRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async chat(requestParameters: ChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatResponseContent> {
        const response = await this.chatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async checkEndpointStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckEndpointStatusResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/checkendpointstatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckEndpointStatusResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async checkEndpointStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckEndpointStatusResponseContent> {
        const response = await this.checkEndpointStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async createDataDialogPromptRaw(requestParameters: CreateDataDialogPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDataDialogPromptResponseContent>> {
        if (requestParameters.createDataDialogPromptRequestContent === null || requestParameters.createDataDialogPromptRequestContent === undefined) {
            throw new runtime.RequiredError('createDataDialogPromptRequestContent','Required parameter requestParameters.createDataDialogPromptRequestContent was null or undefined when calling createDataDialogPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/data-dialog-prompts/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDataDialogPromptRequestContentToJSON(requestParameters.createDataDialogPromptRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDataDialogPromptResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async createDataDialogPrompt(requestParameters: CreateDataDialogPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDataDialogPromptResponseContent> {
        const response = await this.createDataDialogPromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a prompt revision
     */
    async createPromptRevisionRaw(requestParameters: CreatePromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePromptRevisionResponseContent>> {
        if (requestParameters.createPromptRevisionRequestContent === null || requestParameters.createPromptRevisionRequestContent === undefined) {
            throw new runtime.RequiredError('createPromptRevisionRequestContent','Required parameter requestParameters.createPromptRevisionRequestContent was null or undefined when calling createPromptRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePromptRevisionRequestContentToJSON(requestParameters.createPromptRevisionRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePromptRevisionResponseContentFromJSON(jsonValue));
    }

    /**
     * Create a prompt revision
     */
    async createPromptRevision(requestParameters: CreatePromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePromptRevisionResponseContent> {
        const response = await this.createPromptRevisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create an agent
     */
    async createUpdateAgentRaw(requestParameters: CreateUpdateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUpdateAgentResponseContent>> {
        if (requestParameters.createUpdateAgentRequestContent === null || requestParameters.createUpdateAgentRequestContent === undefined) {
            throw new runtime.RequiredError('createUpdateAgentRequestContent','Required parameter requestParameters.createUpdateAgentRequestContent was null or undefined when calling createUpdateAgent.');
        }

        const queryParameters: any = {};

        if (requestParameters.agentId !== undefined) {
            queryParameters['agentId'] = requestParameters.agentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/agents-create-update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateAgentRequestContentToJSON(requestParameters.createUpdateAgentRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUpdateAgentResponseContentFromJSON(jsonValue));
    }

    /**
     * create an agent
     */
    async createUpdateAgent(requestParameters: CreateUpdateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUpdateAgentResponseContent> {
        const response = await this.createUpdateAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAgentRaw(requestParameters: DeleteAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteAgentResponseContent>> {
        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling deleteAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/agents-delete/{agentId}`.replace(`{${"agentId"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteAgentResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async deleteAgent(requestParameters: DeleteAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteAgentResponseContent> {
        const response = await this.deleteAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAgentsRaw(requestParameters: DeleteAgentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteAgentsResponseContent>> {
        if (requestParameters.deleteAgentsRequestContent === null || requestParameters.deleteAgentsRequestContent === undefined) {
            throw new runtime.RequiredError('deleteAgentsRequestContent','Required parameter requestParameters.deleteAgentsRequestContent was null or undefined when calling deleteAgents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/agents-delete`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteAgentsRequestContentToJSON(requestParameters.deleteAgentsRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteAgentsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async deleteAgents(requestParameters: DeleteAgentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteAgentsResponseContent> {
        const response = await this.deleteAgentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteEndpointResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/deleteendpoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteEndpointResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async deleteEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteEndpointResponseContent> {
        const response = await this.deleteEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     * Request a CSV download for all contact QA question in a contact import job
     */
    async exportQAEvaluationToCSVRaw(requestParameters: ExportQAEvaluationToCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportQAEvaluationToCSVResponseContent>> {
        if (requestParameters.exportQAEvaluationToCSVRequestContent === null || requestParameters.exportQAEvaluationToCSVRequestContent === undefined) {
            throw new runtime.RequiredError('exportQAEvaluationToCSVRequestContent','Required parameter requestParameters.exportQAEvaluationToCSVRequestContent was null or undefined when calling exportQAEvaluationToCSV.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/export-qa-evaluation-to-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportQAEvaluationToCSVRequestContentToJSON(requestParameters.exportQAEvaluationToCSVRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportQAEvaluationToCSVResponseContentFromJSON(jsonValue));
    }

    /**
     * Request a CSV download for all contact QA question in a contact import job
     */
    async exportQAEvaluationToCSV(requestParameters: ExportQAEvaluationToCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportQAEvaluationToCSVResponseContent> {
        const response = await this.exportQAEvaluationToCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgentRaw(requestParameters: GetAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgentResponseContent>> {
        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling getAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/agents/{agentId}`.replace(`{${"agentId"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgentResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getAgent(requestParameters: GetAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgentResponseContent> {
        const response = await this.getAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAnalyticsAggregationScoresRaw(requestParameters: GetAnalyticsAggregationScoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAnalyticsAggregationScoresResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling getAnalyticsAggregationScores.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/QA/aggregation/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAnalyticsAggregationScoresRequestContentToJSON(requestParameters.getAnalyticsAggregationScoresRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAnalyticsAggregationScoresResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getAnalyticsAggregationScores(requestParameters: GetAnalyticsAggregationScoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAnalyticsAggregationScoresResponseContent> {
        const response = await this.getAnalyticsAggregationScoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAssessEnabledRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssessEnabledResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/get-assess-enabled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssessEnabledResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getAssessEnabled(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssessEnabledResponseContent> {
        const response = await this.getAssessEnabledRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a batch contact import job
     */
    async getAudioPresignedURLRaw(requestParameters: GetAudioPresignedURLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAudioPresignedURLResponseContent>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getAudioPresignedURL.');
        }

        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling getAudioPresignedURL.');
        }

        const queryParameters: any = {};

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audio/{contactId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAudioPresignedURLResponseContentFromJSON(jsonValue));
    }

    /**
     * Get a batch contact import job
     */
    async getAudioPresignedURL(requestParameters: GetAudioPresignedURLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAudioPresignedURLResponseContent> {
        const response = await this.getAudioPresignedURLRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a batch contact import job
     */
    async getBatchContactImportRaw(requestParameters: GetBatchContactImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBatchContactImportResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling getBatchContactImport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/import/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBatchContactImportResponseContentFromJSON(jsonValue));
    }

    /**
     * Get a batch contact import job
     */
    async getBatchContactImport(requestParameters: GetBatchContactImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBatchContactImportResponseContent> {
        const response = await this.getBatchContactImportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBedrockRegionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBedrockRegionResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/get-bedrock-region`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBedrockRegionResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getBedrockRegion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBedrockRegionResponseContent> {
        const response = await this.getBedrockRegionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get an individual contact
     */
    async getConnectAccessUrlRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetConnectAccessUrlResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/accessUrl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConnectAccessUrlResponseContentFromJSON(jsonValue));
    }

    /**
     * Get an individual contact
     */
    async getConnectAccessUrl(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetConnectAccessUrlResponseContent> {
        const response = await this.getConnectAccessUrlRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get an individual contact
     */
    async getContactRaw(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContactResponseContent>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getContact.');
        }

        const queryParameters: any = {};

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact/{contactId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContactResponseContentFromJSON(jsonValue));
    }

    /**
     * Get an individual contact
     */
    async getContact(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContactResponseContent> {
        const response = await this.getContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual contact\'s metadata
     */
    async getContactMetadataRaw(requestParameters: GetContactMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContactMetadataResponseContent>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getContactMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact-metadata/{contactId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContactMetadataResponseContentFromJSON(jsonValue));
    }

    /**
     * Get an individual contact\'s metadata
     */
    async getContactMetadata(requestParameters: GetContactMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContactMetadataResponseContent> {
        const response = await this.getContactMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDataDialogPromptRaw(requestParameters: GetDataDialogPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDataDialogPromptResponseContent>> {
        if (requestParameters.dataDialogPromptId === null || requestParameters.dataDialogPromptId === undefined) {
            throw new runtime.RequiredError('dataDialogPromptId','Required parameter requestParameters.dataDialogPromptId was null or undefined when calling getDataDialogPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/data-dialog-prompts/get/{dataDialogPromptId}`.replace(`{${"dataDialogPromptId"}}`, encodeURIComponent(String(requestParameters.dataDialogPromptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDataDialogPromptResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getDataDialogPrompt(requestParameters: GetDataDialogPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDataDialogPromptResponseContent> {
        const response = await this.getDataDialogPromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInferenceMethodRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInferenceMethodResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/getinferencemethod`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInferenceMethodResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getInferenceMethod(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInferenceMethodResponseContent> {
        const response = await this.getInferenceMethodRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganisationDetailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrganisationDetailsResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/get-organisation-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganisationDetailsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getOrganisationDetails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrganisationDetailsResponseContent> {
        const response = await this.getOrganisationDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPromptAggregateAnalyticsWithFiltersRaw(requestParameters: GetPromptAggregateAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPromptAggregateAnalyticsWithFiltersResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling getPromptAggregateAnalyticsWithFilters.');
        }

        if (requestParameters.getPromptAggregateAnalyticsWithFiltersRequestContent === null || requestParameters.getPromptAggregateAnalyticsWithFiltersRequestContent === undefined) {
            throw new runtime.RequiredError('getPromptAggregateAnalyticsWithFiltersRequestContent','Required parameter requestParameters.getPromptAggregateAnalyticsWithFiltersRequestContent was null or undefined when calling getPromptAggregateAnalyticsWithFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/promptAggregateAnalytics/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPromptAggregateAnalyticsWithFiltersRequestContentToJSON(requestParameters.getPromptAggregateAnalyticsWithFiltersRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPromptAggregateAnalyticsWithFiltersResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getPromptAggregateAnalyticsWithFilters(requestParameters: GetPromptAggregateAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPromptAggregateAnalyticsWithFiltersResponseContent> {
        const response = await this.getPromptAggregateAnalyticsWithFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual prompt rating
     */
    async getPromptRatingAggregationRaw(requestParameters: GetPromptRatingAggregationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPromptRatingAggregationResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling getPromptRatingAggregation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prompt-rating/{promptRevisionId}`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPromptRatingAggregationResponseContentFromJSON(jsonValue));
    }

    /**
     * Get an individual prompt rating
     */
    async getPromptRatingAggregation(requestParameters: GetPromptRatingAggregationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPromptRatingAggregationResponseContent> {
        const response = await this.getPromptRatingAggregationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get prompt comment reports
     */
    async getPromptReportsRaw(requestParameters: GetPromptReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPromptReportsResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling getPromptReports.');
        }

        if (requestParameters.questionKey === null || requestParameters.questionKey === undefined) {
            throw new runtime.RequiredError('questionKey','Required parameter requestParameters.questionKey was null or undefined when calling getPromptReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.questionKey !== undefined) {
            queryParameters['questionKey'] = requestParameters.questionKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prompt-reports/{promptRevisionId}`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPromptReportsResponseContentFromJSON(jsonValue));
    }

    /**
     * Get prompt comment reports
     */
    async getPromptReports(requestParameters: GetPromptReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPromptReportsResponseContent> {
        const response = await this.getPromptReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual prompt revision
     */
    async getPromptRevisionRaw(requestParameters: GetPromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPromptRevisionResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling getPromptRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prompt/{promptRevisionId}`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPromptRevisionResponseContentFromJSON(jsonValue));
    }

    /**
     * Get an individual prompt revision
     */
    async getPromptRevision(requestParameters: GetPromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPromptRevisionResponseContent> {
        const response = await this.getPromptRevisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPromptTrendAnalyticsWithFiltersRaw(requestParameters: GetPromptTrendAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPromptTrendAnalyticsWithFiltersResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling getPromptTrendAnalyticsWithFilters.');
        }

        if (requestParameters.getPromptTrendAnalyticsWithFiltersRequestContent === null || requestParameters.getPromptTrendAnalyticsWithFiltersRequestContent === undefined) {
            throw new runtime.RequiredError('getPromptTrendAnalyticsWithFiltersRequestContent','Required parameter requestParameters.getPromptTrendAnalyticsWithFiltersRequestContent was null or undefined when calling getPromptTrendAnalyticsWithFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/promptTrendAnalytics/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPromptTrendAnalyticsWithFiltersRequestContentToJSON(requestParameters.getPromptTrendAnalyticsWithFiltersRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPromptTrendAnalyticsWithFiltersResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getPromptTrendAnalyticsWithFilters(requestParameters: GetPromptTrendAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPromptTrendAnalyticsWithFiltersResponseContent> {
        const response = await this.getPromptTrendAnalyticsWithFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the published prompt revision, used for real-time contact summarisation
     */
    async getPublishedPromptRevisionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPublishedPromptRevisionResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/published-prompt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPublishedPromptRevisionResponseContentFromJSON(jsonValue));
    }

    /**
     * Get the published prompt revision, used for real-time contact summarisation
     */
    async getPublishedPromptRevision(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPublishedPromptRevisionResponseContent> {
        const response = await this.getPublishedPromptRevisionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getQAAnalyticsWithFiltersRaw(requestParameters: GetQAAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetQAAnalyticsWithFiltersResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling getQAAnalyticsWithFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/QA/analytics/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetQAAnalyticsWithFiltersRequestContentToJSON(requestParameters.getQAAnalyticsWithFiltersRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetQAAnalyticsWithFiltersResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getQAAnalyticsWithFilters(requestParameters: GetQAAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetQAAnalyticsWithFiltersResponseContent> {
        const response = await this.getQAAnalyticsWithFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQaAggregationSectionScoreRaw(requestParameters: GetQaAggregationSectionScoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetQaAggregationSectionScoreResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling getQaAggregationSectionScore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/QA/aggregatesectionscore/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetQaAggregationSectionScoreRequestContentToJSON(requestParameters.getQaAggregationSectionScoreRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetQaAggregationSectionScoreResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getQaAggregationSectionScore(requestParameters: GetQaAggregationSectionScoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetQaAggregationSectionScoreResponseContent> {
        const response = await this.getQaAggregationSectionScoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual transcript
     */
    async getTranscriptRaw(requestParameters: GetTranscriptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTranscriptResponseContent>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getTranscript.');
        }

        if (requestParameters.transcriptId === null || requestParameters.transcriptId === undefined) {
            throw new runtime.RequiredError('transcriptId','Required parameter requestParameters.transcriptId was null or undefined when calling getTranscript.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact/{contactId}/transcript/{transcriptId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"transcriptId"}}`, encodeURIComponent(String(requestParameters.transcriptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTranscriptResponseContentFromJSON(jsonValue));
    }

    /**
     * Get an individual transcript
     */
    async getTranscript(requestParameters: GetTranscriptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTranscriptResponseContent> {
        const response = await this.getTranscriptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all agents
     */
    async listAgentsRaw(requestParameters: ListAgentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAgentsResponseContent>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling listAgents.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = requestParameters.before;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/agents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAgentsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all agents
     */
    async listAgents(requestParameters: ListAgentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAgentsResponseContent> {
        const response = await this.listAgentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all analytics
     */
    async listAnalyticsRaw(requestParameters: ListAnalyticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAnalyticsResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling listAnalytics.');
        }

        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling listAnalytics.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/{contactImportId}/{promptRevisionId}`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))).replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAnalyticsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all analytics
     */
    async listAnalytics(requestParameters: ListAnalyticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAnalyticsResponseContent> {
        const response = await this.listAnalyticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listAnalyticsWithFiltersRaw(requestParameters: ListAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAnalyticsWithFiltersResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling listAnalyticsWithFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/analytics2/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListAnalyticsWithFiltersRequestContentToJSON(requestParameters.listAnalyticsWithFiltersRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAnalyticsWithFiltersResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listAnalyticsWithFilters(requestParameters: ListAnalyticsWithFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAnalyticsWithFiltersResponseContent> {
        const response = await this.listAnalyticsWithFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all imported contacts for an import job
     */
    async listBatchContactImportResultsRaw(requestParameters: ListBatchContactImportResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListBatchContactImportResultsResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling listBatchContactImportResults.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/import/{contactImportId}/contact`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListBatchContactImportResultsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all imported contacts for an import job
     */
    async listBatchContactImportResults(requestParameters: ListBatchContactImportResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListBatchContactImportResultsResponseContent> {
        const response = await this.listBatchContactImportResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all batch contact import jobs
     */
    async listBatchContactImportsRaw(requestParameters: ListBatchContactImportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListBatchContactImportsResponseContent>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling listBatchContactImports.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = requestParameters.before;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/import`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListBatchContactImportsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all batch contact import jobs
     */
    async listBatchContactImports(requestParameters: ListBatchContactImportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListBatchContactImportsResponseContent> {
        const response = await this.listBatchContactImportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all analytics
     */
    async listContactMetadataColumnsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListContactMetadataColumnsResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/list-columns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListContactMetadataColumnsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all analytics
     */
    async listContactMetadataColumns(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListContactMetadataColumnsResponseContent> {
        const response = await this.listContactMetadataColumnsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all contacts
     */
    async listContactsRaw(requestParameters: ListContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListContactsResponseContent>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling listContacts.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = requestParameters.before;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListContactsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all contacts
     */
    async listContacts(requestParameters: ListContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListContactsResponseContent> {
        const response = await this.listContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all contacts by filters
     */
    async listContactsByFiltersRaw(requestParameters: ListContactsByFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListContactsByFiltersResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/filter-contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListContactsByFiltersRequestContentToJSON(requestParameters.listContactsByFiltersRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListContactsByFiltersResponseContentFromJSON(jsonValue));
    }

    /**
     * List all contacts by filters
     */
    async listContactsByFilters(requestParameters: ListContactsByFiltersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListContactsByFiltersResponseContent> {
        const response = await this.listContactsByFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all contacts metadata on a contact import job
     */
    async listContactsMetadataRaw(requestParameters: ListContactsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListContactsMetadataResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling listContactsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contacts-metadata/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListContactsMetadataResponseContentFromJSON(jsonValue));
    }

    /**
     * List all contacts metadata on a contact import job
     */
    async listContactsMetadata(requestParameters: ListContactsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListContactsMetadataResponseContent> {
        const response = await this.listContactsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listDataDialogPromptsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDataDialogPromptsResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/data-dialog-prompts/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDataDialogPromptsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listDataDialogPrompts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDataDialogPromptsResponseContent> {
        const response = await this.listDataDialogPromptsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all analytics
     */
    async listOptionsForColumnRaw(requestParameters: ListOptionsForColumnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOptionsForColumnResponseContent>> {
        if (requestParameters.columnName === null || requestParameters.columnName === undefined) {
            throw new runtime.RequiredError('columnName','Required parameter requestParameters.columnName was null or undefined when calling listOptionsForColumn.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        if (requestParameters.columnName !== undefined) {
            queryParameters['columnName'] = requestParameters.columnName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/list-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOptionsForColumnResponseContentFromJSON(jsonValue));
    }

    /**
     * List all analytics
     */
    async listOptionsForColumn(requestParameters: ListOptionsForColumnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOptionsForColumnResponseContent> {
        const response = await this.listOptionsForColumnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all prompt revisions
     */
    async listPromptRevisionsRaw(requestParameters: ListPromptRevisionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPromptRevisionsResponseContent>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling listPromptRevisions.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = requestParameters.before;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prompt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPromptRevisionsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all prompt revisions
     */
    async listPromptRevisions(requestParameters: ListPromptRevisionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPromptRevisionsResponseContent> {
        const response = await this.listPromptRevisionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual prompt revision visualisations
     */
    async listPromptVisualisationsRaw(requestParameters: ListPromptVisualisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPromptVisualisationsResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling listPromptVisualisations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prompt-visualisations/{promptRevisionId}`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPromptVisualisationsResponseContentFromJSON(jsonValue));
    }

    /**
     * Get an individual prompt revision visualisations
     */
    async listPromptVisualisations(requestParameters: ListPromptVisualisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPromptVisualisationsResponseContent> {
        const response = await this.listPromptVisualisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all transcripts for a contact
     */
    async listTranscriptsRaw(requestParameters: ListTranscriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTranscriptsResponseContent>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling listTranscripts.');
        }

        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        if (requestParameters.latestFirst !== undefined) {
            queryParameters['latestFirst'] = requestParameters.latestFirst;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact/{contactId}/transcript`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTranscriptsResponseContentFromJSON(jsonValue));
    }

    /**
     * List all transcripts for a contact
     */
    async listTranscripts(requestParameters: ListTranscriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTranscriptsResponseContent> {
        const response = await this.listTranscriptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish a prompt revision, such that it will be used for real-time contact summarisation
     */
    async publishPromptRevisionRaw(requestParameters: PublishPromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublishPromptRevisionResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling publishPromptRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prompt/{promptRevisionId}/publish`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishPromptRevisionResponseContentFromJSON(jsonValue));
    }

    /**
     * Publish a prompt revision, such that it will be used for real-time contact summarisation
     */
    async publishPromptRevision(requestParameters: PublishPromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublishPromptRevisionResponseContent> {
        const response = await this.publishPromptRevisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the rating metrics for this prompt revision across all contacts
     */
    async queryPromptRatingMetricsRaw(requestParameters: QueryPromptRatingMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryPromptRatingMetricsResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling queryPromptRatingMetrics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prompt/{promptRevisionId}/rating`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryPromptRatingMetricsResponseContentFromJSON(jsonValue));
    }

    /**
     * Get the rating metrics for this prompt revision across all contacts
     */
    async queryPromptRatingMetrics(requestParameters: QueryPromptRatingMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryPromptRatingMetricsResponseContent> {
        const response = await this.queryPromptRatingMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request action type on contacts
     */
    async reTryContactActionRaw(requestParameters: ReTryContactActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReTryContactActionResponseContent>> {
        if (requestParameters.contactIds === null || requestParameters.contactIds === undefined) {
            throw new runtime.RequiredError('contactIds','Required parameter requestParameters.contactIds was null or undefined when calling reTryContactAction.');
        }

        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling reTryContactAction.');
        }

        if (requestParameters.actionType === null || requestParameters.actionType === undefined) {
            throw new runtime.RequiredError('actionType','Required parameter requestParameters.actionType was null or undefined when calling reTryContactAction.');
        }

        const queryParameters: any = {};

        if (requestParameters.contactIds) {
            queryParameters['contactIds'] = requestParameters.contactIds;
        }

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        if (requestParameters.actionType !== undefined) {
            queryParameters['actionType'] = requestParameters.actionType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/retry-actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReTryContactActionResponseContentFromJSON(jsonValue));
    }

    /**
     * request action type on contacts
     */
    async reTryContactAction(requestParameters: ReTryContactActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReTryContactActionResponseContent> {
        const response = await this.reTryContactActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start a batch contact import job
     */
    async reimportContactJobRaw(requestParameters: ReimportContactJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReimportContactJobResponseContent>> {
        if (requestParameters.contactImportId === null || requestParameters.contactImportId === undefined) {
            throw new runtime.RequiredError('contactImportId','Required parameter requestParameters.contactImportId was null or undefined when calling reimportContactJob.');
        }

        if (requestParameters.actionType === null || requestParameters.actionType === undefined) {
            throw new runtime.RequiredError('actionType','Required parameter requestParameters.actionType was null or undefined when calling reimportContactJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.actionType !== undefined) {
            queryParameters['actionType'] = requestParameters.actionType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reimport/{contactImportId}`.replace(`{${"contactImportId"}}`, encodeURIComponent(String(requestParameters.contactImportId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReimportContactJobResponseContentFromJSON(jsonValue));
    }

    /**
     * Start a batch contact import job
     */
    async reimportContactJob(requestParameters: ReimportContactJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReimportContactJobResponseContent> {
        const response = await this.reimportContactJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAssessEnabledRaw(requestParameters: SetAssessEnabledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetAssessEnabledResponseContent>> {
        if (requestParameters.isQAEnabled === null || requestParameters.isQAEnabled === undefined) {
            throw new runtime.RequiredError('isQAEnabled','Required parameter requestParameters.isQAEnabled was null or undefined when calling setAssessEnabled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/set-assess-enabled/{isQAEnabled}`.replace(`{${"isQAEnabled"}}`, encodeURIComponent(String(requestParameters.isQAEnabled))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetAssessEnabledResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async setAssessEnabled(requestParameters: SetAssessEnabledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetAssessEnabledResponseContent> {
        const response = await this.setAssessEnabledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setBedrockRegionRaw(requestParameters: SetBedrockRegionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetBedrockRegionResponseContent>> {
        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling setBedrockRegion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/set-bedrock-region/{region}`.replace(`{${"region"}}`, encodeURIComponent(String(requestParameters.region))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetBedrockRegionResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async setBedrockRegion(requestParameters: SetBedrockRegionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetBedrockRegionResponseContent> {
        const response = await this.setBedrockRegionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setInferenceMethodRaw(requestParameters: SetInferenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetInferenceMethodResponseContent>> {
        if (requestParameters.method === null || requestParameters.method === undefined) {
            throw new runtime.RequiredError('method','Required parameter requestParameters.method was null or undefined when calling setInferenceMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/setinferencemethod/{method}`.replace(`{${"method"}}`, encodeURIComponent(String(requestParameters.method))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetInferenceMethodResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async setInferenceMethod(requestParameters: SetInferenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetInferenceMethodResponseContent> {
        const response = await this.setInferenceMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setOrganisationDetailsRaw(requestParameters: SetOrganisationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetOrganisationDetailsResponseContent>> {
        if (requestParameters.setOrganisationDetailsRequestContent === null || requestParameters.setOrganisationDetailsRequestContent === undefined) {
            throw new runtime.RequiredError('setOrganisationDetailsRequestContent','Required parameter requestParameters.setOrganisationDetailsRequestContent was null or undefined when calling setOrganisationDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/set-organisation-details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganisationDetailsRequestContentToJSON(requestParameters.setOrganisationDetailsRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetOrganisationDetailsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async setOrganisationDetails(requestParameters: SetOrganisationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetOrganisationDetailsResponseContent> {
        const response = await this.setOrganisationDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start a batch contact import job
     */
    async startBatchContactImportRaw(requestParameters: StartBatchContactImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StartBatchContactImportResponseContent>> {
        if (requestParameters.startBatchContactImportRequestContent === null || requestParameters.startBatchContactImportRequestContent === undefined) {
            throw new runtime.RequiredError('startBatchContactImportRequestContent','Required parameter requestParameters.startBatchContactImportRequestContent was null or undefined when calling startBatchContactImport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/import`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StartBatchContactImportRequestContentToJSON(requestParameters.startBatchContactImportRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartBatchContactImportResponseContentFromJSON(jsonValue));
    }

    /**
     * Start a batch contact import job
     */
    async startBatchContactImport(requestParameters: StartBatchContactImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StartBatchContactImportResponseContent> {
        const response = await this.startBatchContactImportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async startEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StartEndpointResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/startendpoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartEndpointResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async startEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StartEndpointResponseContent> {
        const response = await this.startEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     * Test a prompt against the given contact
     */
    async testPromptForContactRaw(requestParameters: TestPromptForContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestPromptForContactResponseContent>> {
        if (requestParameters.testPromptForContactRequestContent === null || requestParameters.testPromptForContactRequestContent === undefined) {
            throw new runtime.RequiredError('testPromptForContactRequestContent','Required parameter requestParameters.testPromptForContactRequestContent was null or undefined when calling testPromptForContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prompt/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TestPromptForContactRequestContentToJSON(requestParameters.testPromptForContactRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestPromptForContactResponseContentFromJSON(jsonValue));
    }

    /**
     * Test a prompt against the given contact
     */
    async testPromptForContact(requestParameters: TestPromptForContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestPromptForContactResponseContent> {
        const response = await this.testPromptForContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the evaluation for a contact summary
     */
    async updateContactSummaryEvaluationRaw(requestParameters: UpdateContactSummaryEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateContactSummaryEvaluationResponseContent>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling updateContactSummaryEvaluation.');
        }

        if (requestParameters.updateContactSummaryEvaluationRequestContent === null || requestParameters.updateContactSummaryEvaluationRequestContent === undefined) {
            throw new runtime.RequiredError('updateContactSummaryEvaluationRequestContent','Required parameter requestParameters.updateContactSummaryEvaluationRequestContent was null or undefined when calling updateContactSummaryEvaluation.');
        }

        const queryParameters: any = {};

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contact/{contactId}/summary`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactSummaryEvaluationRequestContentToJSON(requestParameters.updateContactSummaryEvaluationRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateContactSummaryEvaluationResponseContentFromJSON(jsonValue));
    }

    /**
     * Update the evaluation for a contact summary
     */
    async updateContactSummaryEvaluation(requestParameters: UpdateContactSummaryEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateContactSummaryEvaluationResponseContent> {
        const response = await this.updateContactSummaryEvaluationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDataDialogPromptRaw(requestParameters: UpdateDataDialogPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDataDialogPromptResponseContent>> {
        if (requestParameters.dataDialogPromptId === null || requestParameters.dataDialogPromptId === undefined) {
            throw new runtime.RequiredError('dataDialogPromptId','Required parameter requestParameters.dataDialogPromptId was null or undefined when calling updateDataDialogPrompt.');
        }

        if (requestParameters.updateDataDialogPromptRequestContent === null || requestParameters.updateDataDialogPromptRequestContent === undefined) {
            throw new runtime.RequiredError('updateDataDialogPromptRequestContent','Required parameter requestParameters.updateDataDialogPromptRequestContent was null or undefined when calling updateDataDialogPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/data-dialog-prompts/update/{dataDialogPromptId}`.replace(`{${"dataDialogPromptId"}}`, encodeURIComponent(String(requestParameters.dataDialogPromptId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDataDialogPromptRequestContentToJSON(requestParameters.updateDataDialogPromptRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDataDialogPromptResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async updateDataDialogPrompt(requestParameters: UpdateDataDialogPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDataDialogPromptResponseContent> {
        const response = await this.updateDataDialogPromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the evaluation for a contact summary
     */
    async updatePromptFeedbackRaw(requestParameters: UpdatePromptFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePromptFeedbackResponseContent>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling updatePromptFeedback.');
        }

        if (requestParameters.updatePromptFeedbackRequestContent === null || requestParameters.updatePromptFeedbackRequestContent === undefined) {
            throw new runtime.RequiredError('updatePromptFeedbackRequestContent','Required parameter requestParameters.updatePromptFeedbackRequestContent was null or undefined when calling updatePromptFeedback.');
        }

        const queryParameters: any = {};

        if (requestParameters.contactImportId !== undefined) {
            queryParameters['contactImportId'] = requestParameters.contactImportId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contact/{contactId}/feedback`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromptFeedbackRequestContentToJSON(requestParameters.updatePromptFeedbackRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePromptFeedbackResponseContentFromJSON(jsonValue));
    }

    /**
     * Update the evaluation for a contact summary
     */
    async updatePromptFeedback(requestParameters: UpdatePromptFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePromptFeedbackResponseContent> {
        const response = await this.updatePromptFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a QA prompt revision
     */
    async updatePromptRevisionRaw(requestParameters: UpdatePromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePromptRevisionResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling updatePromptRevision.');
        }

        if (requestParameters.updatePromptRevisionRequestContent === null || requestParameters.updatePromptRevisionRequestContent === undefined) {
            throw new runtime.RequiredError('updatePromptRevisionRequestContent','Required parameter requestParameters.updatePromptRevisionRequestContent was null or undefined when calling updatePromptRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prompt/edit/{promptRevisionId}`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromptRevisionRequestContentToJSON(requestParameters.updatePromptRevisionRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePromptRevisionResponseContentFromJSON(jsonValue));
    }

    /**
     * Update a QA prompt revision
     */
    async updatePromptRevision(requestParameters: UpdatePromptRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePromptRevisionResponseContent> {
        const response = await this.updatePromptRevisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a QA prompt revision
     */
    async updatePromptVisualisationsRaw(requestParameters: UpdatePromptVisualisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePromptVisualisationsResponseContent>> {
        if (requestParameters.promptRevisionId === null || requestParameters.promptRevisionId === undefined) {
            throw new runtime.RequiredError('promptRevisionId','Required parameter requestParameters.promptRevisionId was null or undefined when calling updatePromptVisualisations.');
        }

        if (requestParameters.updatePromptVisualisationsRequestContent === null || requestParameters.updatePromptVisualisationsRequestContent === undefined) {
            throw new runtime.RequiredError('updatePromptVisualisationsRequestContent','Required parameter requestParameters.updatePromptVisualisationsRequestContent was null or undefined when calling updatePromptVisualisations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prompt/edit-visualisations/{promptRevisionId}`.replace(`{${"promptRevisionId"}}`, encodeURIComponent(String(requestParameters.promptRevisionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromptVisualisationsRequestContentToJSON(requestParameters.updatePromptVisualisationsRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePromptVisualisationsResponseContentFromJSON(jsonValue));
    }

    /**
     * Update a QA prompt revision
     */
    async updatePromptVisualisations(requestParameters: UpdatePromptVisualisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePromptVisualisationsResponseContent> {
        const response = await this.updatePromptVisualisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
