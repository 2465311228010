/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';

/**
 * Represents a Transcript
 * @export
 * @interface Transcript
 */
export interface Transcript {
    /**
     * Associated contact id
     * @type {string}
     * @memberof Transcript
     */
    contactId: string;
    /**
     * Amazon connect contact channel
     * @type {string}
     * @memberof Transcript
     */
    channel: string;
    /**
     * 
     * @type {Participant}
     * @memberof Transcript
     */
    participant: Participant;
    /**
     * The string content for the transcript
     * @type {string}
     * @memberof Transcript
     */
    content: string;
    /**
     * Time at which this transcript started relative to the start of the contact
     * @type {number}
     * @memberof Transcript
     */
    startOffset: number;
    /**
     * Time at which this transcript finished relative to the start of the contact
     * @type {number}
     * @memberof Transcript
     */
    endOffset: number;
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof Transcript
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof Transcript
     */
    updatedAt: number;
    /**
     * Identifier for a transcript
     * @type {string}
     * @memberof Transcript
     */
    transcriptId: string;
}


/**
 * Check if a given object implements the Transcript interface.
 */
export function instanceOfTranscript(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactId" in value;
    isInstance = isInstance && "channel" in value;
    isInstance = isInstance && "participant" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "startOffset" in value;
    isInstance = isInstance && "endOffset" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "transcriptId" in value;

    return isInstance;
}

export function TranscriptFromJSON(json: any): Transcript {
    return TranscriptFromJSONTyped(json, false);
}

export function TranscriptFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transcript {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactId': json['contactId'],
        'channel': json['channel'],
        'participant': ParticipantFromJSON(json['participant']),
        'content': json['content'],
        'startOffset': json['startOffset'],
        'endOffset': json['endOffset'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'transcriptId': json['transcriptId'],
    };
}

export function TranscriptToJSON(value?: Transcript | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactId': value.contactId,
        'channel': value.channel,
        'participant': ParticipantToJSON(value.participant),
        'content': value.content,
        'startOffset': value.startOffset,
        'endOffset': value.endOffset,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'transcriptId': value.transcriptId,
    };
}

