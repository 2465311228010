/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import AuthProvider from "./providers/AuthProvider";
import DefaultApiClientProvider from "./providers/DefaultApiClientProvider";
import { NotificationContextProvider } from "./providers/NotificationContextProvider";
import RuntimeContextProvider from "./providers/RuntimeContextProvider";
import "./styles/global.css";

// using compile time approach instead, but this might be useful:
// const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <RuntimeContextProvider>
        <AuthProvider>
          <NotificationContextProvider>
            <DefaultApiClientProvider>
              <App />
            </DefaultApiClientProvider>
          </NotificationContextProvider>
        </AuthProvider>
      </RuntimeContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
