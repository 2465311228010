/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListContactsResponseContent
 */
export interface ListContactsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListContactsResponseContent
     */
    nextToken?: string;
    /**
     * List of contacts
     * @type {Array<Contact>}
     * @memberof ListContactsResponseContent
     */
    contacts: Array<Contact>;
}


/**
 * Check if a given object implements the ListContactsResponseContent interface.
 */
export function instanceOfListContactsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contacts" in value;

    return isInstance;
}

export function ListContactsResponseContentFromJSON(json: any): ListContactsResponseContent {
    return ListContactsResponseContentFromJSONTyped(json, false);
}

export function ListContactsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListContactsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'contacts': ((json['contacts'] as Array<any>).map(ContactFromJSON)),
    };
}

export function ListContactsResponseContentToJSON(value?: ListContactsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'contacts': ((value.contacts as Array<any>).map(ContactToJSON)),
    };
}

