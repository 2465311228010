/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClassValue } from './ClassValue';
import {
    ClassValueFromJSON,
    ClassValueFromJSONTyped,
    ClassValueToJSON,
} from './ClassValue';

/**
 * 
 * @export
 * @interface GetPromptAggregateAnalyticsWithFiltersResponseContent
 */
export interface GetPromptAggregateAnalyticsWithFiltersResponseContent {
    /**
     * Map of PromptAggregateAnalytics in JSON format
     * @type {Array<ClassValue>}
     * @memberof GetPromptAggregateAnalyticsWithFiltersResponseContent
     */
    analytics: Array<ClassValue>;
}


/**
 * Check if a given object implements the GetPromptAggregateAnalyticsWithFiltersResponseContent interface.
 */
export function instanceOfGetPromptAggregateAnalyticsWithFiltersResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "analytics" in value;

    return isInstance;
}

export function GetPromptAggregateAnalyticsWithFiltersResponseContentFromJSON(json: any): GetPromptAggregateAnalyticsWithFiltersResponseContent {
    return GetPromptAggregateAnalyticsWithFiltersResponseContentFromJSONTyped(json, false);
}

export function GetPromptAggregateAnalyticsWithFiltersResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPromptAggregateAnalyticsWithFiltersResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'analytics': ((json['analytics'] as Array<any>).map(ClassValueFromJSON)),
    };
}

export function GetPromptAggregateAnalyticsWithFiltersResponseContentToJSON(value?: GetPromptAggregateAnalyticsWithFiltersResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'analytics': ((value.analytics as Array<any>).map(ClassValueToJSON)),
    };
}

