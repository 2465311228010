/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type ContactImportStage =
  'IMPORT_REQ' | 
  'TRANSCRIBE_REQ' | 
  'INFERENCE_REQ' | 
  'COMPLETED'


export function ContactImportStageFromJSON(json: any): ContactImportStage {
    return ContactImportStageFromJSONTyped(json, false);
}

export function ContactImportStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactImportStage {
    return json as ContactImportStage;
}

export function ContactImportStageToJSON(value?: ContactImportStage | null): any {
    return value as any;
}

