/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactDetailsFilter } from './ContactDetailsFilter';
import {
    ContactDetailsFilterFromJSON,
    ContactDetailsFilterFromJSONTyped,
    ContactDetailsFilterToJSON,
} from './ContactDetailsFilter';

/**
 * 
 * @export
 * @interface GetAnalyticsAggregationScoresRequestContent
 */
export interface GetAnalyticsAggregationScoresRequestContent {
    /**
     * Return analytics on contacts created before this timestamp
     * @type {string}
     * @memberof GetAnalyticsAggregationScoresRequestContent
     */
    from?: string;
    /**
     * Return analytics on contacts after this timestamp
     * @type {string}
     * @memberof GetAnalyticsAggregationScoresRequestContent
     */
    to?: string;
    /**
     * 
     * @type {Array<ContactDetailsFilter>}
     * @memberof GetAnalyticsAggregationScoresRequestContent
     */
    filters?: Array<ContactDetailsFilter>;
}


/**
 * Check if a given object implements the GetAnalyticsAggregationScoresRequestContent interface.
 */
export function instanceOfGetAnalyticsAggregationScoresRequestContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAnalyticsAggregationScoresRequestContentFromJSON(json: any): GetAnalyticsAggregationScoresRequestContent {
    return GetAnalyticsAggregationScoresRequestContentFromJSONTyped(json, false);
}

export function GetAnalyticsAggregationScoresRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAnalyticsAggregationScoresRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'filters': !exists(json, 'filters') ? undefined : ((json['filters'] as Array<any>).map(ContactDetailsFilterFromJSON)),
    };
}

export function GetAnalyticsAggregationScoresRequestContentToJSON(value?: GetAnalyticsAggregationScoresRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
        'filters': value.filters === undefined ? undefined : ((value.filters as Array<any>).map(ContactDetailsFilterToJSON)),
    };
}

