/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SynopsisEvaluationDetails } from './SynopsisEvaluationDetails';
import {
    SynopsisEvaluationDetailsFromJSON,
    SynopsisEvaluationDetailsFromJSONTyped,
    SynopsisEvaluationDetailsToJSON,
} from './SynopsisEvaluationDetails';

/**
 * 
 * @export
 * @interface UpdateContactSummaryEvaluationRequestContent
 */
export interface UpdateContactSummaryEvaluationRequestContent {
    /**
     * 
     * @type {SynopsisEvaluationDetails}
     * @memberof UpdateContactSummaryEvaluationRequestContent
     */
    evaluation: SynopsisEvaluationDetails;
}


/**
 * Check if a given object implements the UpdateContactSummaryEvaluationRequestContent interface.
 */
export function instanceOfUpdateContactSummaryEvaluationRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "evaluation" in value;

    return isInstance;
}

export function UpdateContactSummaryEvaluationRequestContentFromJSON(json: any): UpdateContactSummaryEvaluationRequestContent {
    return UpdateContactSummaryEvaluationRequestContentFromJSONTyped(json, false);
}

export function UpdateContactSummaryEvaluationRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateContactSummaryEvaluationRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evaluation': SynopsisEvaluationDetailsFromJSON(json['evaluation']),
    };
}

export function UpdateContactSummaryEvaluationRequestContentToJSON(value?: UpdateContactSummaryEvaluationRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evaluation': SynopsisEvaluationDetailsToJSON(value.evaluation),
    };
}

