/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactSummary } from './ContactSummary';
import {
    ContactSummaryFromJSON,
    ContactSummaryFromJSONTyped,
    ContactSummaryToJSON,
} from './ContactSummary';

/**
 * 
 * @export
 * @interface Summaries
 */
export interface Summaries {
    /**
     * 
     * @type {ContactSummary}
     * @memberof Summaries
     */
    qa?: ContactSummary;
    /**
     * 
     * @type {ContactSummary}
     * @memberof Summaries
     */
    production?: ContactSummary;
}


/**
 * Check if a given object implements the Summaries interface.
 */
export function instanceOfSummaries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SummariesFromJSON(json: any): Summaries {
    return SummariesFromJSONTyped(json, false);
}

export function SummariesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Summaries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'qa': !exists(json, 'qa') ? undefined : ContactSummaryFromJSON(json['qa']),
        'production': !exists(json, 'production') ? undefined : ContactSummaryFromJSON(json['production']),
    };
}

export function SummariesToJSON(value?: Summaries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'qa': ContactSummaryToJSON(value.qa),
        'production': ContactSummaryToJSON(value.production),
    };
}

