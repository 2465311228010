/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import { Menu } from "lucide-react";
import { ReactNode, useState } from "react";

import Breadcrumbs from "./NavBar/Breadcrumbs";
import SideBar from "./SideBar/SideBar";
import { Button } from "../components/ui/button";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { cn } from "../lib/utils";

interface AppLayoutProps {
  children: ReactNode;
  isLightMode: boolean;
}

const AppLayout = ({ isLightMode, children }: AppLayoutProps) => {
  const [initialMaxScreenSize] = useLocalStorage("max-app-width", "wide", (_key, value) => setMaxScreenSize(value));
  const [maxScreenSize, setMaxScreenSize] = useState(initialMaxScreenSize);
  const [isDesktopOpen, setDesktopOpen] = useState(false);
  const [isMobileOpen, setMobileOpen] = useState(false);

  const showSideBar = () => setDesktopOpen(true);
  const hideSideBar = () => setDesktopOpen(false);
  const toggleHover = () => setMobileOpen((p) => !p);
  const disableHover = () => setMobileOpen(false);

  const screenSizeClass = maxScreenSize === "normal" ? "max-w-screen-lg" : undefined;

  return (
    <>
      <div
        // overlay for when nav panels are open
        className={cn(
          "absolute w-full bg-background h-full transition-opacity z-10",
          isMobileOpen || isDesktopOpen ? "opacity-50" : "opacity-0 pointer-events-none",
        )}
        {...(isMobileOpen && { onClick: disableHover })}
      />
      <div className={cn("flex p-[10px] mx-auto my-0", screenSizeClass)}>
        <div className="flex flex-1 flex-col items-center w-full gap-y-4">
          <Button variant={"ghost"} onClick={toggleHover} className="fixed top-2 right-2 z-50 visible sm:invisible">
            <Menu />
          </Button>

          {isMobileOpen && (
            <SideBar
              // Mobile navigation, assumes full screen width when browser width is under 500px:
              isLightMode={isLightMode}
              className="flex flex-col bg-card rounded-b-md absolute w-full max-w-64 max-[500px]:max-w-[100%] right-0 top-0 shadow-md z-10"
              onClick={disableHover}
            />
          )}

          <div className="grid w-full grid-cols-[1fr] sm:grid-cols-[64px_1fr] h-[calc(100vh-20px)]">
            <SideBar
              // Desktop navigation:
              isLightMode={isLightMode}
              width={isDesktopOpen ? 240 : 48}
              onMouseEnter={showSideBar}
              onMouseLeave={hideSideBar}
              className={"hidden sm:flex sm:flex-col h-full z-10"}
            />
            <div
              className={cn(
                "flex flex-1 flex-col w-full gap-5 items-center overflow-auto",
                "p-1", // important:needs p-1 so overflow does not cut off shadows in light mode!
              )}
            >
              <Breadcrumbs />
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
