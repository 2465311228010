import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function downloadFromLink(presignedUrl: string) {
  const link = document.createElement("a");
  if (presignedUrl) {
    link.href = presignedUrl;
    link.target = "_blank";
    link.click();
  }
}
