/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterContactResult
 */
export interface FilterContactResult {
    /**
     * 
     * @type {string}
     * @memberof FilterContactResult
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterContactResult
     */
    value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FilterContactResult
     */
    isSuper?: boolean;
}


/**
 * Check if a given object implements the FilterContactResult interface.
 */
export function instanceOfFilterContactResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterContactResultFromJSON(json: any): FilterContactResult {
    return FilterContactResultFromJSONTyped(json, false);
}

export function FilterContactResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterContactResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'isSuper': !exists(json, 'isSuper') ? undefined : json['isSuper'],
    };
}

export function FilterContactResultToJSON(value?: FilterContactResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
        'isSuper': value.isSuper,
    };
}

