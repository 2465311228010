/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataDialogPromptStatus } from './DataDialogPromptStatus';
import {
    DataDialogPromptStatusFromJSON,
    DataDialogPromptStatusFromJSONTyped,
    DataDialogPromptStatusToJSON,
} from './DataDialogPromptStatus';

/**
 * A prompt for data dialog
 * @export
 * @interface DataDialogPrompt
 */
export interface DataDialogPrompt {
    /**
     * 
     * @type {string}
     * @memberof DataDialogPrompt
     */
    dataDialogPromptId: string;
    /**
     * creation time
     * @type {string}
     * @memberof DataDialogPrompt
     */
    createdAt: string;
    /**
     * The creator's id
     * @type {string}
     * @memberof DataDialogPrompt
     */
    createdBy: string;
    /**
     * Whether this prompt was creatd by the current user
     * @type {boolean}
     * @memberof DataDialogPrompt
     */
    isOwner: boolean;
    /**
     * The text of the prompt
     * @type {string}
     * @memberof DataDialogPrompt
     */
    promptText: string;
    /**
     * 
     * @type {DataDialogPromptStatus}
     * @memberof DataDialogPrompt
     */
    status: DataDialogPromptStatus;
}


/**
 * Check if a given object implements the DataDialogPrompt interface.
 */
export function instanceOfDataDialogPrompt(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataDialogPromptId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "isOwner" in value;
    isInstance = isInstance && "promptText" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function DataDialogPromptFromJSON(json: any): DataDialogPrompt {
    return DataDialogPromptFromJSONTyped(json, false);
}

export function DataDialogPromptFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataDialogPrompt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataDialogPromptId': json['dataDialogPromptId'],
        'createdAt': json['createdAt'],
        'createdBy': json['createdBy'],
        'isOwner': json['isOwner'],
        'promptText': json['promptText'],
        'status': DataDialogPromptStatusFromJSON(json['status']),
    };
}

export function DataDialogPromptToJSON(value?: DataDialogPrompt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataDialogPromptId': value.dataDialogPromptId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'isOwner': value.isOwner,
        'promptText': value.promptText,
        'status': DataDialogPromptStatusToJSON(value.status),
    };
}

