/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValueTypes } from './ValueTypes';
import {
    ValueTypesFromJSON,
    ValueTypesFromJSONTyped,
    ValueTypesToJSON,
} from './ValueTypes';

/**
 * 
 * @export
 * @interface AnalyticsData
 */
export interface AnalyticsData {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsData
     */
    key?: string;
    /**
     * 
     * @type {ValueTypes}
     * @memberof AnalyticsData
     */
    value?: ValueTypes;
}


/**
 * Check if a given object implements the AnalyticsData interface.
 */
export function instanceOfAnalyticsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnalyticsDataFromJSON(json: any): AnalyticsData {
    return AnalyticsDataFromJSONTyped(json, false);
}

export function AnalyticsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : ValueTypesFromJSON(json['value']),
    };
}

export function AnalyticsDataToJSON(value?: AnalyticsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': ValueTypesToJSON(value.value),
    };
}

