/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartBatchContactImportRequestContent
 */
export interface StartBatchContactImportRequestContent {
    /**
     * Name of the import
     * @type {string}
     * @memberof StartBatchContactImportRequestContent
     */
    name: string;
    /**
     * The S3 bucket from which contacts should be imported
     * @type {string}
     * @memberof StartBatchContactImportRequestContent
     */
    bucket: string;
    /**
     * ID of the prompt revision for this import
     * @type {string}
     * @memberof StartBatchContactImportRequestContent
     */
    promptRevisionId?: string;
    /**
     * Optional prefix within the bucket from which contacts should be imported
     * When omitted, all contacts from the bucket will be used
     * @type {string}
     * @memberof StartBatchContactImportRequestContent
     */
    key?: string;
}


/**
 * Check if a given object implements the StartBatchContactImportRequestContent interface.
 */
export function instanceOfStartBatchContactImportRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "bucket" in value;

    return isInstance;
}

export function StartBatchContactImportRequestContentFromJSON(json: any): StartBatchContactImportRequestContent {
    return StartBatchContactImportRequestContentFromJSONTyped(json, false);
}

export function StartBatchContactImportRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartBatchContactImportRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'bucket': json['bucket'],
        'promptRevisionId': !exists(json, 'promptRevisionId') ? undefined : json['promptRevisionId'],
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function StartBatchContactImportRequestContentToJSON(value?: StartBatchContactImportRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'bucket': value.bucket,
        'promptRevisionId': value.promptRevisionId,
        'key': value.key,
    };
}

