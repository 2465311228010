/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionVisualisation } from './QuestionVisualisation';
import {
    QuestionVisualisationFromJSON,
    QuestionVisualisationFromJSONTyped,
    QuestionVisualisationToJSON,
} from './QuestionVisualisation';

/**
 * 
 * @export
 * @interface UpdatePromptVisualisationsRequestContent
 */
export interface UpdatePromptVisualisationsRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePromptVisualisationsRequestContent
     */
    questionId: string;
    /**
     * 
     * @type {QuestionVisualisation}
     * @memberof UpdatePromptVisualisationsRequestContent
     */
    visualisation: QuestionVisualisation;
}


/**
 * Check if a given object implements the UpdatePromptVisualisationsRequestContent interface.
 */
export function instanceOfUpdatePromptVisualisationsRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "questionId" in value;
    isInstance = isInstance && "visualisation" in value;

    return isInstance;
}

export function UpdatePromptVisualisationsRequestContentFromJSON(json: any): UpdatePromptVisualisationsRequestContent {
    return UpdatePromptVisualisationsRequestContentFromJSONTyped(json, false);
}

export function UpdatePromptVisualisationsRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePromptVisualisationsRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': json['questionId'],
        'visualisation': QuestionVisualisationFromJSON(json['visualisation']),
    };
}

export function UpdatePromptVisualisationsRequestContentToJSON(value?: UpdatePromptVisualisationsRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'visualisation': QuestionVisualisationToJSON(value.visualisation),
    };
}

