/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * How the contact arrived in the application
 * @export
 * @enum {string}
 */
export type ContactSource =
  'REAL_TIME' | 
  'IMPORT'


export function ContactSourceFromJSON(json: any): ContactSource {
    return ContactSourceFromJSONTyped(json, false);
}

export function ContactSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSource {
    return json as ContactSource;
}

export function ContactSourceToJSON(value?: ContactSource | null): any {
    return value as any;
}

