/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImportedContactMetadata } from './ImportedContactMetadata';
import {
    ImportedContactMetadataFromJSON,
    ImportedContactMetadataFromJSONTyped,
    ImportedContactMetadataToJSON,
} from './ImportedContactMetadata';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListContactsMetadataResponseContent
 */
export interface ListContactsMetadataResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListContactsMetadataResponseContent
     */
    nextToken?: string;
    /**
     * List of contacts metadata
     * @type {Array<ImportedContactMetadata>}
     * @memberof ListContactsMetadataResponseContent
     */
    metadata: Array<ImportedContactMetadata>;
}


/**
 * Check if a given object implements the ListContactsMetadataResponseContent interface.
 */
export function instanceOfListContactsMetadataResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function ListContactsMetadataResponseContentFromJSON(json: any): ListContactsMetadataResponseContent {
    return ListContactsMetadataResponseContentFromJSONTyped(json, false);
}

export function ListContactsMetadataResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListContactsMetadataResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'metadata': ((json['metadata'] as Array<any>).map(ImportedContactMetadataFromJSON)),
    };
}

export function ListContactsMetadataResponseContentToJSON(value?: ListContactsMetadataResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'metadata': ((value.metadata as Array<any>).map(ImportedContactMetadataToJSON)),
    };
}

