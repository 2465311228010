/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type PromptGroupType =
  'synopsis' | 
  'qa'


export function PromptGroupTypeFromJSON(json: any): PromptGroupType {
    return PromptGroupTypeFromJSONTyped(json, false);
}

export function PromptGroupTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptGroupType {
    return json as PromptGroupType;
}

export function PromptGroupTypeToJSON(value?: PromptGroupType | null): any {
    return value as any;
}

