/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type REGION =
  'us-east-1' | 
  'us-east-2' | 
  'us-west-1' | 
  'us-west-2' | 
  'af-south-1' | 
  'ap-east-1' | 
  'ap-south-1' | 
  'ap-south-2' | 
  'ap-northeast-1' | 
  'ap-northeast-2' | 
  'ap-northeast-3' | 
  'ap-southeast-1' | 
  'ap-southeast-2' | 
  'ap-southeast-3' | 
  'ap-southeast-4' | 
  'ca-central-1' | 
  'ca-west-1' | 
  'cn-north-1' | 
  'cn-northwest-1' | 
  'eu-central-1' | 
  'eu-central-2' | 
  'eu-west-1' | 
  'eu-west-2' | 
  'eu-west-3' | 
  'eu-north-1' | 
  'eu-south-1' | 
  'eu-south-2' | 
  'il-central-1' | 
  'me-south-1' | 
  'me-central-1' | 
  'sa-east-1'


export function REGIONFromJSON(json: any): REGION {
    return REGIONFromJSONTyped(json, false);
}

export function REGIONFromJSONTyped(json: any, ignoreDiscriminator: boolean): REGION {
    return json as REGION;
}

export function REGIONToJSON(value?: REGION | null): any {
    return value as any;
}

