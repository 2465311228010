/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * 
 * @export
 * @interface ChatResponseContent
 */
export interface ChatResponseContent {
    /**
     * Map of Categories in JSON format
     * @type {Array<Message>}
     * @memberof ChatResponseContent
     */
    messages: Array<Message>;
}


/**
 * Check if a given object implements the ChatResponseContent interface.
 */
export function instanceOfChatResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "messages" in value;

    return isInstance;
}

export function ChatResponseContentFromJSON(json: any): ChatResponseContent {
    return ChatResponseContentFromJSONTyped(json, false);
}

export function ChatResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messages': ((json['messages'] as Array<any>).map(MessageFromJSON)),
    };
}

export function ChatResponseContentToJSON(value?: ChatResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messages': ((value.messages as Array<any>).map(MessageToJSON)),
    };
}

