/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgentDetails } from './AgentDetails';
import {
    AgentDetailsFromJSON,
    AgentDetailsFromJSONTyped,
    AgentDetailsToJSON,
} from './AgentDetails';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListAgentsResponseContent
 */
export interface ListAgentsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListAgentsResponseContent
     */
    nextToken?: string;
    /**
     * List of agents
     * @type {Array<AgentDetails>}
     * @memberof ListAgentsResponseContent
     */
    agents: Array<AgentDetails>;
}


/**
 * Check if a given object implements the ListAgentsResponseContent interface.
 */
export function instanceOfListAgentsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agents" in value;

    return isInstance;
}

export function ListAgentsResponseContentFromJSON(json: any): ListAgentsResponseContent {
    return ListAgentsResponseContentFromJSONTyped(json, false);
}

export function ListAgentsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAgentsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'agents': ((json['agents'] as Array<any>).map(AgentDetailsFromJSON)),
    };
}

export function ListAgentsResponseContentToJSON(value?: ListAgentsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'agents': ((value.agents as Array<any>).map(AgentDetailsToJSON)),
    };
}

