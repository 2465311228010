/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import { cva, VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../../lib/utils";

const loaderVariants = cva("rounded-full border-2 animate-spin", {
  variants: {
    variant: {
      default: "bg-transparent border-background border-t-primary ",
    },
    size: {
      default: "h-8 w-8",
      xs: "h-4 w-4",
      sm: "h-6 w-6",
      lg: "h-10 w-10",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const Loader = React.forwardRef<HTMLDivElement, LoaderProps & VariantProps<typeof loaderVariants>>(
  ({ className, variant, size, ...props }, ref) => {
    return <div ref={ref} className={cn(loaderVariants({ variant, size, className }))} {...props} />;
  },
);
Loader.displayName = "Loader";

export default Loader;
