/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteAgentsRequestContent
 */
export interface DeleteAgentsRequestContent {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteAgentsRequestContent
     */
    agentIds: Array<string>;
}


/**
 * Check if a given object implements the DeleteAgentsRequestContent interface.
 */
export function instanceOfDeleteAgentsRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agentIds" in value;

    return isInstance;
}

export function DeleteAgentsRequestContentFromJSON(json: any): DeleteAgentsRequestContent {
    return DeleteAgentsRequestContentFromJSONTyped(json, false);
}

export function DeleteAgentsRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteAgentsRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agentIds': json['agentIds'],
    };
}

export function DeleteAgentsRequestContentToJSON(value?: DeleteAgentsRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agentIds': value.agentIds,
    };
}

