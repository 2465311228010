/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Details about an identity calling the api
 * @export
 * @interface CallingIdentity
 */
export interface CallingIdentity {
    /**
     * Identifier for the user
     * @type {string}
     * @memberof CallingIdentity
     */
    identityId: string;
    /**
     * User's usernname
     * @type {string}
     * @memberof CallingIdentity
     */
    username: string;
}


/**
 * Check if a given object implements the CallingIdentity interface.
 */
export function instanceOfCallingIdentity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "identityId" in value;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function CallingIdentityFromJSON(json: any): CallingIdentity {
    return CallingIdentityFromJSONTyped(json, false);
}

export function CallingIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallingIdentity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identityId': json['identityId'],
        'username': json['username'],
    };
}

export function CallingIdentityToJSON(value?: CallingIdentity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identityId': value.identityId,
        'username': value.username,
    };
}

