/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValuesForColumn } from './ValuesForColumn';
import {
    ValuesForColumnFromJSON,
    ValuesForColumnFromJSONTyped,
    ValuesForColumnToJSON,
} from './ValuesForColumn';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListOptionsForColumnResponseContent
 */
export interface ListOptionsForColumnResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListOptionsForColumnResponseContent
     */
    nextToken?: string;
    /**
     * 
     * @type {number}
     * @memberof ListOptionsForColumnResponseContent
     */
    totalPages?: number;
    /**
     * 
     * @type {ValuesForColumn}
     * @memberof ListOptionsForColumnResponseContent
     */
    results?: ValuesForColumn;
}


/**
 * Check if a given object implements the ListOptionsForColumnResponseContent interface.
 */
export function instanceOfListOptionsForColumnResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListOptionsForColumnResponseContentFromJSON(json: any): ListOptionsForColumnResponseContent {
    return ListOptionsForColumnResponseContentFromJSONTyped(json, false);
}

export function ListOptionsForColumnResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOptionsForColumnResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'results': !exists(json, 'results') ? undefined : ValuesForColumnFromJSON(json['results']),
    };
}

export function ListOptionsForColumnResponseContentToJSON(value?: ListOptionsForColumnResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'totalPages': value.totalPages,
        'results': ValuesForColumnToJSON(value.results),
    };
}

