/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishPromptRevisionResponseContent
 */
export interface PublishPromptRevisionResponseContent {
    /**
     * ID of the prompt revision which was published
     * @type {string}
     * @memberof PublishPromptRevisionResponseContent
     */
    publishedRevisionId: string;
    /**
     * ID of the prompt revision which was replaced
     * @type {string}
     * @memberof PublishPromptRevisionResponseContent
     */
    replacedRevisionId?: string;
}


/**
 * Check if a given object implements the PublishPromptRevisionResponseContent interface.
 */
export function instanceOfPublishPromptRevisionResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "publishedRevisionId" in value;

    return isInstance;
}

export function PublishPromptRevisionResponseContentFromJSON(json: any): PublishPromptRevisionResponseContent {
    return PublishPromptRevisionResponseContentFromJSONTyped(json, false);
}

export function PublishPromptRevisionResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishPromptRevisionResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publishedRevisionId': json['publishedRevisionId'],
        'replacedRevisionId': !exists(json, 'replacedRevisionId') ? undefined : json['replacedRevisionId'],
    };
}

export function PublishPromptRevisionResponseContentToJSON(value?: PublishPromptRevisionResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publishedRevisionId': value.publishedRevisionId,
        'replacedRevisionId': value.replacedRevisionId,
    };
}

