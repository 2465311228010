/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category3 } from './Category3';
import {
    Category3FromJSON,
    Category3FromJSONTyped,
    Category3ToJSON,
} from './Category3';

/**
 * 
 * @export
 * @interface Category3Map
 */
export interface Category3Map {
    [key: string]: Category3;
}


/**
 * Check if a given object implements the Category3Map interface.
 */
export function instanceOfCategory3Map(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Category3MapFromJSON(json: any): Category3Map {
    return Category3MapFromJSONTyped(json, false);
}

export function Category3MapFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category3Map {
    return json;
}

export function Category3MapToJSON(value?: Category3Map | null): any {
    return value;
}

