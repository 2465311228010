/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionVisualisation } from './QuestionVisualisation';
import {
    QuestionVisualisationFromJSON,
    QuestionVisualisationFromJSONTyped,
    QuestionVisualisationToJSON,
} from './QuestionVisualisation';

/**
 * 
 * @export
 * @interface Properties
 */
export interface Properties {
    /**
     * 
     * @type {number}
     * @memberof Properties
     */
    weight: number;
    /**
     * 
     * @type {boolean}
     * @memberof Properties
     */
    autoFail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Properties
     */
    positiveResult: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Properties
     */
    conditionalQA: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Properties
     */
    isParent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Properties
     */
    isChild: boolean;
    /**
     * 
     * @type {Array<QuestionVisualisation>}
     * @memberof Properties
     */
    promptVisualisations?: Array<QuestionVisualisation>;
}


/**
 * Check if a given object implements the Properties interface.
 */
export function instanceOfProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "autoFail" in value;
    isInstance = isInstance && "positiveResult" in value;
    isInstance = isInstance && "conditionalQA" in value;
    isInstance = isInstance && "isParent" in value;
    isInstance = isInstance && "isChild" in value;

    return isInstance;
}

export function PropertiesFromJSON(json: any): Properties {
    return PropertiesFromJSONTyped(json, false);
}

export function PropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Properties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weight': json['weight'],
        'autoFail': json['autoFail'],
        'positiveResult': json['positiveResult'],
        'conditionalQA': json['conditionalQA'],
        'isParent': json['isParent'],
        'isChild': json['isChild'],
        'promptVisualisations': !exists(json, 'promptVisualisations') ? undefined : ((json['promptVisualisations'] as Array<any>).map(QuestionVisualisationFromJSON)),
    };
}

export function PropertiesToJSON(value?: Properties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weight': value.weight,
        'autoFail': value.autoFail,
        'positiveResult': value.positiveResult,
        'conditionalQA': value.conditionalQA,
        'isParent': value.isParent,
        'isChild': value.isChild,
        'promptVisualisations': value.promptVisualisations === undefined ? undefined : ((value.promptVisualisations as Array<any>).map(QuestionVisualisationToJSON)),
    };
}

