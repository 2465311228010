/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type PromptType =
  'synthesis' | 
  'extraction_binary' | 
  'extraction_single_label' | 
  'extraction_rating' | 
  'extraction_hierarchy' | 
  'chat'


export function PromptTypeFromJSON(json: any): PromptType {
    return PromptTypeFromJSONTyped(json, false);
}

export function PromptTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptType {
    return json as PromptType;
}

export function PromptTypeToJSON(value?: PromptType | null): any {
    return value as any;
}

