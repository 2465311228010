/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromptRevision } from './PromptRevision';
import {
    PromptRevisionFromJSON,
    PromptRevisionFromJSONTyped,
    PromptRevisionToJSON,
} from './PromptRevision';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListPromptRevisionsResponseContent
 */
export interface ListPromptRevisionsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListPromptRevisionsResponseContent
     */
    nextToken?: string;
    /**
     * List of prompt revisions
     * @type {Array<PromptRevision>}
     * @memberof ListPromptRevisionsResponseContent
     */
    revisions: Array<PromptRevision>;
}


/**
 * Check if a given object implements the ListPromptRevisionsResponseContent interface.
 */
export function instanceOfListPromptRevisionsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "revisions" in value;

    return isInstance;
}

export function ListPromptRevisionsResponseContentFromJSON(json: any): ListPromptRevisionsResponseContent {
    return ListPromptRevisionsResponseContentFromJSONTyped(json, false);
}

export function ListPromptRevisionsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPromptRevisionsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'revisions': ((json['revisions'] as Array<any>).map(PromptRevisionFromJSON)),
    };
}

export function ListPromptRevisionsResponseContentToJSON(value?: ListPromptRevisionsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'revisions': ((value.revisions as Array<any>).map(PromptRevisionToJSON)),
    };
}

