/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnalyticsData } from './AnalyticsData';
import {
    AnalyticsDataFromJSON,
    AnalyticsDataFromJSONTyped,
    AnalyticsDataToJSON,
} from './AnalyticsData';

/**
 * 
 * @export
 * @interface QAAnalyticsResults
 */
export interface QAAnalyticsResults {
    [key: string]: Array<AnalyticsData>;
}


/**
 * Check if a given object implements the QAAnalyticsResults interface.
 */
export function instanceOfQAAnalyticsResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QAAnalyticsResultsFromJSON(json: any): QAAnalyticsResults {
    return QAAnalyticsResultsFromJSONTyped(json, false);
}

export function QAAnalyticsResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QAAnalyticsResults {
    return json;
}

export function QAAnalyticsResultsToJSON(value?: QAAnalyticsResults | null): any {
    return value;
}

