/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category3Map } from './Category3Map';
import {
    Category3MapFromJSON,
    Category3MapFromJSONTyped,
    Category3MapToJSON,
} from './Category3Map';
import type { ClassValue } from './ClassValue';
import {
    ClassValueFromJSON,
    ClassValueFromJSONTyped,
    ClassValueToJSON,
} from './ClassValue';

/**
 * An element of a synopsis
 * @export
 * @interface Category2
 */
export interface Category2 {
    /**
     * The name of the category
     * @type {string}
     * @memberof Category2
     */
    name: string;
    /**
     * The percentage of calls which belong to this category
     * @type {number}
     * @memberof Category2
     */
    percentage: number;
    /**
     * The number of contacts in this category
     * @type {number}
     * @memberof Category2
     */
    numcontacts: number;
    /**
     * 
     * @type {Category3Map}
     * @memberof Category2
     */
    children?: Category3Map;
    /**
     * 
     * @type {Array<ClassValue>}
     * @memberof Category2
     */
    customerSentiments?: Array<ClassValue>;
    /**
     * 
     * @type {Array<ClassValue>}
     * @memberof Category2
     */
    agentSentiments?: Array<ClassValue>;
    /**
     * 
     * @type {Array<ClassValue>}
     * @memberof Category2
     */
    resolutionRate?: Array<ClassValue>;
    /**
     * 
     * @type {Array<ClassValue>}
     * @memberof Category2
     */
    repeatCalls?: Array<ClassValue>;
}


/**
 * Check if a given object implements the Category2 interface.
 */
export function instanceOfCategory2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "percentage" in value;
    isInstance = isInstance && "numcontacts" in value;

    return isInstance;
}

export function Category2FromJSON(json: any): Category2 {
    return Category2FromJSONTyped(json, false);
}

export function Category2FromJSONTyped(json: any, ignoreDiscriminator: boolean): Category2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'percentage': json['percentage'],
        'numcontacts': json['numcontacts'],
        'children': !exists(json, 'children') ? undefined : Category3MapFromJSON(json['children']),
        'customerSentiments': !exists(json, 'customerSentiments') ? undefined : ((json['customerSentiments'] as Array<any>).map(ClassValueFromJSON)),
        'agentSentiments': !exists(json, 'agentSentiments') ? undefined : ((json['agentSentiments'] as Array<any>).map(ClassValueFromJSON)),
        'resolutionRate': !exists(json, 'resolutionRate') ? undefined : ((json['resolutionRate'] as Array<any>).map(ClassValueFromJSON)),
        'repeatCalls': !exists(json, 'repeatCalls') ? undefined : ((json['repeatCalls'] as Array<any>).map(ClassValueFromJSON)),
    };
}

export function Category2ToJSON(value?: Category2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'percentage': value.percentage,
        'numcontacts': value.numcontacts,
        'children': Category3MapToJSON(value.children),
        'customerSentiments': value.customerSentiments === undefined ? undefined : ((value.customerSentiments as Array<any>).map(ClassValueToJSON)),
        'agentSentiments': value.agentSentiments === undefined ? undefined : ((value.agentSentiments as Array<any>).map(ClassValueToJSON)),
        'resolutionRate': value.resolutionRate === undefined ? undefined : ((value.resolutionRate as Array<any>).map(ClassValueToJSON)),
        'repeatCalls': value.repeatCalls === undefined ? undefined : ((value.repeatCalls as Array<any>).map(ClassValueToJSON)),
    };
}

