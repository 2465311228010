/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelBoolean
 */
export interface ModelBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ModelBoolean
     */
    _boolean: boolean;
}


/**
 * Check if a given object implements the ModelBoolean interface.
 */
export function instanceOfModelBoolean(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "_boolean" in value;

    return isInstance;
}

export function ModelBooleanFromJSON(json: any): ModelBoolean {
    return ModelBooleanFromJSONTyped(json, false);
}

export function ModelBooleanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelBoolean {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_boolean': json['boolean'],
    };
}

export function ModelBooleanToJSON(value?: ModelBoolean | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boolean': value._boolean,
    };
}

