/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactImportAggrSectionScores
 */
export interface ContactImportAggrSectionScores {
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrSectionScores
     */
    contactImportId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrSectionScores
     */
    promptGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrSectionScores
     */
    scorePercentage: string;
}


/**
 * Check if a given object implements the ContactImportAggrSectionScores interface.
 */
export function instanceOfContactImportAggrSectionScores(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactImportId" in value;
    isInstance = isInstance && "promptGroupName" in value;
    isInstance = isInstance && "scorePercentage" in value;

    return isInstance;
}

export function ContactImportAggrSectionScoresFromJSON(json: any): ContactImportAggrSectionScores {
    return ContactImportAggrSectionScoresFromJSONTyped(json, false);
}

export function ContactImportAggrSectionScoresFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactImportAggrSectionScores {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactImportId': json['contactImportId'],
        'promptGroupName': json['promptGroupName'],
        'scorePercentage': json['scorePercentage'],
    };
}

export function ContactImportAggrSectionScoresToJSON(value?: ContactImportAggrSectionScores | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactImportId': value.contactImportId,
        'promptGroupName': value.promptGroupName,
        'scorePercentage': value.scorePercentage,
    };
}

