/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import React from "react";

import { cn } from "../../lib/utils";

// CardPrimary: main card for laying out (sets of) components. should sit in ./page/PageContainer:
const CardPrimary = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "CardPrimary", // just dev's reference to identify in DOM.
        "flex w-full flex-col items-start gap-y-4 p-6 rounded-md bg-card border border-card",
        className,
      )}
      {...props}
    />
  ),
);
CardPrimary.displayName = "CardPrimary";

// CardSecondary: second level card for highlighting/grouping sets of components, should sit inside CardPrimary:
export const CardSecondary = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "CardSecondary", // just dev's reference to identify in DOM.
        "flex w-full flex-col items-start gap-y-4 p-6 rounded-md bg-card-high border border-card-high",
        className,
      )}
      {...props}
    />
  ),
);
CardSecondary.displayName = "CardSecondary";

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("rounded-lg border bg-card text-card-foreground shadow-sm", className)} {...props} />
));
Card.displayName = "Card";

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h3 ref={ref} className={cn("text-2xl font-semibold leading-none tracking-tight", className)} {...props} />
  ),
);
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn("p-0", className)} {...props} />,
);
CardContent.displayName = "CardContent";

export { CardPrimary, Card, CardTitle, CardContent };
