/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactImport } from './ContactImport';
import {
    ContactImportFromJSON,
    ContactImportFromJSONTyped,
    ContactImportToJSON,
} from './ContactImport';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListBatchContactImportsResponseContent
 */
export interface ListBatchContactImportsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListBatchContactImportsResponseContent
     */
    nextToken?: string;
    /**
     * List of contact import jobs
     * @type {Array<ContactImport>}
     * @memberof ListBatchContactImportsResponseContent
     */
    imports: Array<ContactImport>;
}


/**
 * Check if a given object implements the ListBatchContactImportsResponseContent interface.
 */
export function instanceOfListBatchContactImportsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imports" in value;

    return isInstance;
}

export function ListBatchContactImportsResponseContentFromJSON(json: any): ListBatchContactImportsResponseContent {
    return ListBatchContactImportsResponseContentFromJSONTyped(json, false);
}

export function ListBatchContactImportsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListBatchContactImportsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'imports': ((json['imports'] as Array<any>).map(ContactImportFromJSON)),
    };
}

export function ListBatchContactImportsResponseContentToJSON(value?: ListBatchContactImportsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'imports': ((value.imports as Array<any>).map(ContactImportToJSON)),
    };
}

