/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgentDetails } from './AgentDetails';
import {
    AgentDetailsFromJSON,
    AgentDetailsFromJSONTyped,
    AgentDetailsToJSON,
} from './AgentDetails';

/**
 * 
 * @export
 * @interface GetAgentResponseContent
 */
export interface GetAgentResponseContent {
    /**
     * 
     * @type {AgentDetails}
     * @memberof GetAgentResponseContent
     */
    agent?: AgentDetails;
}


/**
 * Check if a given object implements the GetAgentResponseContent interface.
 */
export function instanceOfGetAgentResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAgentResponseContentFromJSON(json: any): GetAgentResponseContent {
    return GetAgentResponseContentFromJSONTyped(json, false);
}

export function GetAgentResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgentResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agent': !exists(json, 'agent') ? undefined : AgentDetailsFromJSON(json['agent']),
    };
}

export function GetAgentResponseContentToJSON(value?: GetAgentResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agent': AgentDetailsToJSON(value.agent),
    };
}

