import * as React from "react";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AgentAssistApi } from "./AgentAssistApi";
import { AgentAssistApiClientContext } from "./AgentAssistApiHooks";

const queryClient = new QueryClient();

/**
 * Default QueryClient context for AgentAssistApi
 */
export const AgentAssistApiDefaultContext = React.createContext<QueryClient | undefined>(
  undefined
);

/**
 * Properties for the AgentAssistApiClientProvider
 */
export interface AgentAssistApiClientProviderProps {
  readonly apiClient: AgentAssistApi;
  readonly client?: QueryClient;
  readonly context?: React.Context<QueryClient | undefined>;
  readonly children?: React.ReactNode;
}

/**
 * Provider for the API Client and Query Client used by the hooks.
 * This must parent any components which make use of the hooks.
 */
export const AgentAssistApiClientProvider = ({
  apiClient,
  client = queryClient,
  context = AgentAssistApiDefaultContext,
  children,
}: AgentAssistApiClientProviderProps): JSX.Element => {
  return (
    <QueryClientProvider client={client} context={context}>
      <AgentAssistApiClientContext.Provider value={apiClient}>
        {children}
      </AgentAssistApiClientContext.Provider>
    </QueryClientProvider>
  );
};
