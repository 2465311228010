/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallingIdentity } from './CallingIdentity';
import {
    CallingIdentityFromJSON,
    CallingIdentityFromJSONTyped,
    CallingIdentityToJSON,
} from './CallingIdentity';
import type { ContactImportStatus } from './ContactImportStatus';
import {
    ContactImportStatusFromJSON,
    ContactImportStatusFromJSONTyped,
    ContactImportStatusToJSON,
} from './ContactImportStatus';
import type { QaCSVMetadata } from './QaCSVMetadata';
import {
    QaCSVMetadataFromJSON,
    QaCSVMetadataFromJSONTyped,
    QaCSVMetadataToJSON,
} from './QaCSVMetadata';

/**
 * A contact import job
 * @export
 * @interface GetBatchContactImportResponseContent
 */
export interface GetBatchContactImportResponseContent {
    /**
     * Name of the import
     * @type {string}
     * @memberof GetBatchContactImportResponseContent
     */
    name: string;
    /**
     * The S3 bucket from which contacts should be imported
     * @type {string}
     * @memberof GetBatchContactImportResponseContent
     */
    bucket: string;
    /**
     * ID of the prompt revision for this import
     * @type {string}
     * @memberof GetBatchContactImportResponseContent
     */
    promptRevisionId: string;
    /**
     * Optional prefix within the bucket from which contacts should be imported
     * When omitted, all contacts from the bucket will be used
     * @type {string}
     * @memberof GetBatchContactImportResponseContent
     */
    key?: string;
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof GetBatchContactImportResponseContent
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof GetBatchContactImportResponseContent
     */
    updatedAt: number;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof GetBatchContactImportResponseContent
     */
    createdBy: CallingIdentity;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof GetBatchContactImportResponseContent
     */
    updatedBy: CallingIdentity;
    /**
     * ID of the job
     * @type {string}
     * @memberof GetBatchContactImportResponseContent
     */
    contactImportId: string;
    /**
     * 
     * @type {ContactImportStatus}
     * @memberof GetBatchContactImportResponseContent
     */
    status: ContactImportStatus;
    /**
     * Details about the status, if any
     * @type {string}
     * @memberof GetBatchContactImportResponseContent
     */
    statusDetails?: string;
    /**
     * Count of all contacts to process
     * @type {number}
     * @memberof GetBatchContactImportResponseContent
     */
    totalCount?: number;
    /**
     * Count of processed contacts
     * @type {number}
     * @memberof GetBatchContactImportResponseContent
     */
    processedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchContactImportResponseContent
     */
    totalNeedProcessing?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBatchContactImportResponseContent
     */
    qaPromptRevisionId?: string;
    /**
     * 
     * @type {QaCSVMetadata}
     * @memberof GetBatchContactImportResponseContent
     */
    qaCSVMetadata?: QaCSVMetadata;
    /**
     * Count of failed contacts
     * @type {number}
     * @memberof GetBatchContactImportResponseContent
     */
    failedCount?: number;
}


/**
 * Check if a given object implements the GetBatchContactImportResponseContent interface.
 */
export function instanceOfGetBatchContactImportResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "bucket" in value;
    isInstance = isInstance && "promptRevisionId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "updatedBy" in value;
    isInstance = isInstance && "contactImportId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetBatchContactImportResponseContentFromJSON(json: any): GetBatchContactImportResponseContent {
    return GetBatchContactImportResponseContentFromJSONTyped(json, false);
}

export function GetBatchContactImportResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBatchContactImportResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'bucket': json['bucket'],
        'promptRevisionId': json['promptRevisionId'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'createdBy': CallingIdentityFromJSON(json['createdBy']),
        'updatedBy': CallingIdentityFromJSON(json['updatedBy']),
        'contactImportId': json['contactImportId'],
        'status': ContactImportStatusFromJSON(json['status']),
        'statusDetails': !exists(json, 'statusDetails') ? undefined : json['statusDetails'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'processedCount': !exists(json, 'processedCount') ? undefined : json['processedCount'],
        'totalNeedProcessing': !exists(json, 'totalNeedProcessing') ? undefined : json['totalNeedProcessing'],
        'qaPromptRevisionId': !exists(json, 'qaPromptRevisionId') ? undefined : json['qaPromptRevisionId'],
        'qaCSVMetadata': !exists(json, 'qaCSVMetadata') ? undefined : QaCSVMetadataFromJSON(json['qaCSVMetadata']),
        'failedCount': !exists(json, 'failedCount') ? undefined : json['failedCount'],
    };
}

export function GetBatchContactImportResponseContentToJSON(value?: GetBatchContactImportResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'bucket': value.bucket,
        'promptRevisionId': value.promptRevisionId,
        'key': value.key,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'createdBy': CallingIdentityToJSON(value.createdBy),
        'updatedBy': CallingIdentityToJSON(value.updatedBy),
        'contactImportId': value.contactImportId,
        'status': ContactImportStatusToJSON(value.status),
        'statusDetails': value.statusDetails,
        'totalCount': value.totalCount,
        'processedCount': value.processedCount,
        'totalNeedProcessing': value.totalNeedProcessing,
        'qaPromptRevisionId': value.qaPromptRevisionId,
        'qaCSVMetadata': QaCSVMetadataToJSON(value.qaCSVMetadata),
        'failedCount': value.failedCount,
    };
}

