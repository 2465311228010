/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetOrganisationDetailsResponseContent
 */
export interface SetOrganisationDetailsResponseContent {
    /**
     * 
     * @type {string}
     * @memberof SetOrganisationDetailsResponseContent
     */
    message?: string;
}


/**
 * Check if a given object implements the SetOrganisationDetailsResponseContent interface.
 */
export function instanceOfSetOrganisationDetailsResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SetOrganisationDetailsResponseContentFromJSON(json: any): SetOrganisationDetailsResponseContent {
    return SetOrganisationDetailsResponseContentFromJSONTyped(json, false);
}

export function SetOrganisationDetailsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganisationDetailsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function SetOrganisationDetailsResponseContentToJSON(value?: SetOrganisationDetailsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}

