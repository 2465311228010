/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import { useEffect } from "react";

import { useLocalStorage } from "./useLocalStorage";

import { setLightMode as dispatchSetLightMode, useTrusstGPTDispatch } from "../providers/AppProvider";

// Before using this hook, you probably don't need to! You can get lightmode from `useTrusstGPTState`
export const useLightMode = (): [boolean, () => void] => {
  const dispatch = useTrusstGPTDispatch();
  const [isLightMode, setLightMode] = useLocalStorage("light-mode", false);

  useEffect(() => {
    if (isLightMode) {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    } else {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    }
    dispatchSetLightMode(dispatch, isLightMode);
  }, [isLightMode]);

  const toggleLightMode = () => setLightMode(!isLightMode);

  return [isLightMode, toggleLightMode];
};

export default useLightMode;
