/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportQAEvaluationToCSVRequestContent
 */
export interface ExportQAEvaluationToCSVRequestContent {
    /**
     * contact import
     * @type {string}
     * @memberof ExportQAEvaluationToCSVRequestContent
     */
    contactImportId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExportQAEvaluationToCSVRequestContent
     */
    regenerate?: boolean;
}


/**
 * Check if a given object implements the ExportQAEvaluationToCSVRequestContent interface.
 */
export function instanceOfExportQAEvaluationToCSVRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactImportId" in value;

    return isInstance;
}

export function ExportQAEvaluationToCSVRequestContentFromJSON(json: any): ExportQAEvaluationToCSVRequestContent {
    return ExportQAEvaluationToCSVRequestContentFromJSONTyped(json, false);
}

export function ExportQAEvaluationToCSVRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportQAEvaluationToCSVRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactImportId': json['contactImportId'],
        'regenerate': !exists(json, 'regenerate') ? undefined : json['regenerate'],
    };
}

export function ExportQAEvaluationToCSVRequestContentToJSON(value?: ExportQAEvaluationToCSVRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactImportId': value.contactImportId,
        'regenerate': value.regenerate,
    };
}

