/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataDialogPrompt } from './DataDialogPrompt';
import {
    DataDialogPromptFromJSON,
    DataDialogPromptFromJSONTyped,
    DataDialogPromptToJSON,
} from './DataDialogPrompt';

/**
 * 
 * @export
 * @interface GetDataDialogPromptResponseContent
 */
export interface GetDataDialogPromptResponseContent {
    /**
     * 
     * @type {DataDialogPrompt}
     * @memberof GetDataDialogPromptResponseContent
     */
    prompt?: DataDialogPrompt;
}


/**
 * Check if a given object implements the GetDataDialogPromptResponseContent interface.
 */
export function instanceOfGetDataDialogPromptResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetDataDialogPromptResponseContentFromJSON(json: any): GetDataDialogPromptResponseContent {
    return GetDataDialogPromptResponseContentFromJSONTyped(json, false);
}

export function GetDataDialogPromptResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDataDialogPromptResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prompt': !exists(json, 'prompt') ? undefined : DataDialogPromptFromJSON(json['prompt']),
    };
}

export function GetDataDialogPromptResponseContentToJSON(value?: GetDataDialogPromptResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prompt': DataDialogPromptToJSON(value.prompt),
    };
}

