/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileS3Location } from './FileS3Location';
import {
    FileS3LocationFromJSON,
    FileS3LocationFromJSONTyped,
    FileS3LocationToJSON,
} from './FileS3Location';

/**
 * Extends structures with details about when the resource was created/updated
 * @export
 * @interface ImportedContactMetadata
 */
export interface ImportedContactMetadata {
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof ImportedContactMetadata
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof ImportedContactMetadata
     */
    updatedAt: number;
    /**
     * ID of the job
     * @type {string}
     * @memberof ImportedContactMetadata
     */
    contactImportId: string;
    /**
     * ID of the contact imported
     * @type {string}
     * @memberof ImportedContactMetadata
     */
    contactId: string;
    /**
     * 
     * @type {FileS3Location}
     * @memberof ImportedContactMetadata
     */
    s3Location: FileS3Location;
    /**
     * 
     * @type {string}
     * @memberof ImportedContactMetadata
     */
    metadata: string;
}


/**
 * Check if a given object implements the ImportedContactMetadata interface.
 */
export function instanceOfImportedContactMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "contactImportId" in value;
    isInstance = isInstance && "contactId" in value;
    isInstance = isInstance && "s3Location" in value;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function ImportedContactMetadataFromJSON(json: any): ImportedContactMetadata {
    return ImportedContactMetadataFromJSONTyped(json, false);
}

export function ImportedContactMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportedContactMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'contactImportId': json['contactImportId'],
        'contactId': json['contactId'],
        's3Location': FileS3LocationFromJSON(json['s3Location']),
        'metadata': json['metadata'],
    };
}

export function ImportedContactMetadataToJSON(value?: ImportedContactMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'contactImportId': value.contactImportId,
        'contactId': value.contactId,
        's3Location': FileS3LocationToJSON(value.s3Location),
        'metadata': value.metadata,
    };
}

