/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromptGroup } from './PromptGroup';
import {
    PromptGroupFromJSON,
    PromptGroupFromJSONTyped,
    PromptGroupToJSON,
} from './PromptGroup';

/**
 * 
 * @export
 * @interface PromptGroups
 */
export interface PromptGroups {
    [key: string]: PromptGroup;
}


/**
 * Check if a given object implements the PromptGroups interface.
 */
export function instanceOfPromptGroups(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PromptGroupsFromJSON(json: any): PromptGroups {
    return PromptGroupsFromJSONTyped(json, false);
}

export function PromptGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptGroups {
    return json;
}

export function PromptGroupsToJSON(value?: PromptGroups | null): any {
    return value;
}

