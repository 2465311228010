/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VisualisationType } from './VisualisationType';
import {
    VisualisationTypeFromJSON,
    VisualisationTypeFromJSONTyped,
    VisualisationTypeToJSON,
} from './VisualisationType';

/**
 * 
 * @export
 * @interface QuestionVisualisation
 */
export interface QuestionVisualisation {
    /**
     * 
     * @type {boolean}
     * @memberof QuestionVisualisation
     */
    enabled: boolean;
    /**
     * 
     * @type {VisualisationType}
     * @memberof QuestionVisualisation
     */
    visualisationType: VisualisationType;
    /**
     * 
     * @type {string}
     * @memberof QuestionVisualisation
     */
    tooltip?: string;
}


/**
 * Check if a given object implements the QuestionVisualisation interface.
 */
export function instanceOfQuestionVisualisation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "visualisationType" in value;

    return isInstance;
}

export function QuestionVisualisationFromJSON(json: any): QuestionVisualisation {
    return QuestionVisualisationFromJSONTyped(json, false);
}

export function QuestionVisualisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionVisualisation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'visualisationType': VisualisationTypeFromJSON(json['visualisationType']),
        'tooltip': !exists(json, 'tooltip') ? undefined : json['tooltip'],
    };
}

export function QuestionVisualisationToJSON(value?: QuestionVisualisation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'visualisationType': VisualisationTypeToJSON(value.visualisationType),
        'tooltip': value.tooltip,
    };
}

