/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An internal failure at the fault of the server
 * @export
 * @interface InternalFailureErrorResponseContent
 */
export interface InternalFailureErrorResponseContent {
    /**
     * Message with details about the error
     * @type {string}
     * @memberof InternalFailureErrorResponseContent
     */
    message: string;
}


/**
 * Check if a given object implements the InternalFailureErrorResponseContent interface.
 */
export function instanceOfInternalFailureErrorResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function InternalFailureErrorResponseContentFromJSON(json: any): InternalFailureErrorResponseContent {
    return InternalFailureErrorResponseContentFromJSONTyped(json, false);
}

export function InternalFailureErrorResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalFailureErrorResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function InternalFailureErrorResponseContentToJSON(value?: InternalFailureErrorResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}

