/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Report } from './Report';
import {
    ReportFromJSON,
    ReportFromJSONTyped,
    ReportToJSON,
} from './Report';

/**
 * 
 * @export
 * @interface PromptReports
 */
export interface PromptReports {
    [key: string]: Array<Report>;
}


/**
 * Check if a given object implements the PromptReports interface.
 */
export function instanceOfPromptReports(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PromptReportsFromJSON(json: any): PromptReports {
    return PromptReportsFromJSONTyped(json, false);
}

export function PromptReportsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptReports {
    return json;
}

export function PromptReportsToJSON(value?: PromptReports | null): any {
    return value;
}

