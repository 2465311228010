/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FilterContactResult } from './FilterContactResult';
import {
    FilterContactResultFromJSON,
    FilterContactResultFromJSONTyped,
    FilterContactResultToJSON,
} from './FilterContactResult';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListContactsByFiltersResponseContent
 */
export interface ListContactsByFiltersResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListContactsByFiltersResponseContent
     */
    nextToken?: string;
    /**
     * 
     * @type {number}
     * @memberof ListContactsByFiltersResponseContent
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<Array<FilterContactResult>>}
     * @memberof ListContactsByFiltersResponseContent
     */
    results?: Array<Array<FilterContactResult>>;
}


/**
 * Check if a given object implements the ListContactsByFiltersResponseContent interface.
 */
export function instanceOfListContactsByFiltersResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListContactsByFiltersResponseContentFromJSON(json: any): ListContactsByFiltersResponseContent {
    return ListContactsByFiltersResponseContentFromJSONTyped(json, false);
}

export function ListContactsByFiltersResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListContactsByFiltersResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'results': !exists(json, 'results') ? undefined : json['results'],
    };
}

export function ListContactsByFiltersResponseContentToJSON(value?: ListContactsByFiltersResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'totalPages': value.totalPages,
        'results': value.results,
    };
}

