/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgentDetails } from './AgentDetails';
import {
    AgentDetailsFromJSON,
    AgentDetailsFromJSONTyped,
    AgentDetailsToJSON,
} from './AgentDetails';

/**
 * 
 * @export
 * @interface CreateUpdateAgentResponseContent
 */
export interface CreateUpdateAgentResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAgentResponseContent
     */
    message?: string;
    /**
     * 
     * @type {AgentDetails}
     * @memberof CreateUpdateAgentResponseContent
     */
    agent?: AgentDetails;
}


/**
 * Check if a given object implements the CreateUpdateAgentResponseContent interface.
 */
export function instanceOfCreateUpdateAgentResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateUpdateAgentResponseContentFromJSON(json: any): CreateUpdateAgentResponseContent {
    return CreateUpdateAgentResponseContentFromJSONTyped(json, false);
}

export function CreateUpdateAgentResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateAgentResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'agent': !exists(json, 'agent') ? undefined : AgentDetailsFromJSON(json['agent']),
    };
}

export function CreateUpdateAgentResponseContentToJSON(value?: CreateUpdateAgentResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'agent': AgentDetailsToJSON(value.agent),
    };
}

