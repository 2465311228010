/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FilterContactResult } from './FilterContactResult';
import {
    FilterContactResultFromJSON,
    FilterContactResultFromJSONTyped,
    FilterContactResultToJSON,
} from './FilterContactResult';

/**
 * 
 * @export
 * @interface GetContactMetadataResponseContent
 */
export interface GetContactMetadataResponseContent {
    /**
     * 
     * @type {Array<Array<FilterContactResult>>}
     * @memberof GetContactMetadataResponseContent
     */
    result?: Array<Array<FilterContactResult>>;
}


/**
 * Check if a given object implements the GetContactMetadataResponseContent interface.
 */
export function instanceOfGetContactMetadataResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetContactMetadataResponseContentFromJSON(json: any): GetContactMetadataResponseContent {
    return GetContactMetadataResponseContentFromJSONTyped(json, false);
}

export function GetContactMetadataResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactMetadataResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function GetContactMetadataResponseContentToJSON(value?: GetContactMetadataResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
    };
}

