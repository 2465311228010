/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TrendAnalyticsData } from './TrendAnalyticsData';
import {
    TrendAnalyticsDataFromJSON,
    TrendAnalyticsDataFromJSONTyped,
    TrendAnalyticsDataToJSON,
} from './TrendAnalyticsData';

/**
 * 
 * @export
 * @interface GetPromptTrendAnalyticsWithFiltersResponseContent
 */
export interface GetPromptTrendAnalyticsWithFiltersResponseContent {
    /**
     * Map of PromptTrendAnalytics in JSON format
     * @type {Array<TrendAnalyticsData>}
     * @memberof GetPromptTrendAnalyticsWithFiltersResponseContent
     */
    analytics: Array<TrendAnalyticsData>;
}


/**
 * Check if a given object implements the GetPromptTrendAnalyticsWithFiltersResponseContent interface.
 */
export function instanceOfGetPromptTrendAnalyticsWithFiltersResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "analytics" in value;

    return isInstance;
}

export function GetPromptTrendAnalyticsWithFiltersResponseContentFromJSON(json: any): GetPromptTrendAnalyticsWithFiltersResponseContent {
    return GetPromptTrendAnalyticsWithFiltersResponseContentFromJSONTyped(json, false);
}

export function GetPromptTrendAnalyticsWithFiltersResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPromptTrendAnalyticsWithFiltersResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'analytics': ((json['analytics'] as Array<any>).map(TrendAnalyticsDataFromJSON)),
    };
}

export function GetPromptTrendAnalyticsWithFiltersResponseContentToJSON(value?: GetPromptTrendAnalyticsWithFiltersResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'analytics': ((value.analytics as Array<any>).map(TrendAnalyticsDataToJSON)),
    };
}

