/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetOrganisationDetailsRequestContent
 */
export interface SetOrganisationDetailsRequestContent {
    /**
     * 
     * @type {string}
     * @memberof SetOrganisationDetailsRequestContent
     */
    organisationName: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganisationDetailsRequestContent
     */
    organisationDescription: string;
}


/**
 * Check if a given object implements the SetOrganisationDetailsRequestContent interface.
 */
export function instanceOfSetOrganisationDetailsRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organisationName" in value;
    isInstance = isInstance && "organisationDescription" in value;

    return isInstance;
}

export function SetOrganisationDetailsRequestContentFromJSON(json: any): SetOrganisationDetailsRequestContent {
    return SetOrganisationDetailsRequestContentFromJSONTyped(json, false);
}

export function SetOrganisationDetailsRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganisationDetailsRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisationName': json['organisationName'],
        'organisationDescription': json['organisationDescription'],
    };
}

export function SetOrganisationDetailsRequestContentToJSON(value?: SetOrganisationDetailsRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisationName': value.organisationName,
        'organisationDescription': value.organisationDescription,
    };
}

