/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type TrendInterval =
  '15Min' | 
  'Hour' | 
  'Day' | 
  'Week' | 
  'Month' | 
  'Year'


export function TrendIntervalFromJSON(json: any): TrendInterval {
    return TrendIntervalFromJSONTyped(json, false);
}

export function TrendIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrendInterval {
    return json as TrendInterval;
}

export function TrendIntervalToJSON(value?: TrendInterval | null): any {
    return value as any;
}

