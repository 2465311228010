/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ModelBoolean,
    instanceOfModelBoolean,
    ModelBooleanFromJSON,
    ModelBooleanFromJSONTyped,
    ModelBooleanToJSON,
} from './ModelBoolean';
import {
    ModelInteger,
    instanceOfModelInteger,
    ModelIntegerFromJSON,
    ModelIntegerFromJSONTyped,
    ModelIntegerToJSON,
} from './ModelInteger';
import {
    ModelString,
    instanceOfModelString,
    ModelStringFromJSON,
    ModelStringFromJSONTyped,
    ModelStringToJSON,
} from './ModelString';

/**
 * @type ValueTypes
 * 
 * @export
 */
export type ValueTypes = ModelBoolean | ModelInteger | ModelString;

export function ValueTypesFromJSON(json: any): ValueTypes {
    return ValueTypesFromJSONTyped(json, false);
}

export function ValueTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValueTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ModelBooleanFromJSONTyped(json, true), ...ModelIntegerFromJSONTyped(json, true), ...ModelStringFromJSONTyped(json, true) };
}

export function ValueTypesToJSON(value?: ValueTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfModelBoolean(value)) {
        return ModelBooleanToJSON(value as ModelBoolean);
    }
    if (instanceOfModelInteger(value)) {
        return ModelIntegerToJSON(value as ModelInteger);
    }
    if (instanceOfModelString(value)) {
        return ModelStringToJSON(value as ModelString);
    }

    return {};
}

