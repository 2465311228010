/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

import { cn } from "../../lib/utils";

interface SideBarButtonProps {
  icon: ReactNode;
  strongBackground?: boolean; // slight variant with a stronger background.
  text: string;
  to: string;
}

const SideBarButton = ({ icon, strongBackground, text, to }: SideBarButtonProps) => {
  const location = useLocation();

  const isActivePath = location.pathname.startsWith(to);

  return (
    <Link
      to={to}
      className={cn(
        "flex flex-row cursor-pointer transition-none rounded-md px-2 py-2 mt-1",
        "opacity-60 hover:opacity-80",
        isActivePath ? "opacity-100 hover:opacity-100 bg-card-high" : strongBackground ? "bg-background" : undefined,
      )}
    >
      <div className="w-[24px] mr-4 relative">{icon}</div>
      <p className="block truncate">{text}</p>
    </Link>
  );
};

export default SideBarButton;
