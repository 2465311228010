/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Transcript } from './Transcript';
import {
    TranscriptFromJSON,
    TranscriptFromJSONTyped,
    TranscriptToJSON,
} from './Transcript';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListTranscriptsResponseContent
 */
export interface ListTranscriptsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListTranscriptsResponseContent
     */
    nextToken?: string;
    /**
     * List of Transcripts
     * @type {Array<Transcript>}
     * @memberof ListTranscriptsResponseContent
     */
    transcripts: Array<Transcript>;
}


/**
 * Check if a given object implements the ListTranscriptsResponseContent interface.
 */
export function instanceOfListTranscriptsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transcripts" in value;

    return isInstance;
}

export function ListTranscriptsResponseContentFromJSON(json: any): ListTranscriptsResponseContent {
    return ListTranscriptsResponseContentFromJSONTyped(json, false);
}

export function ListTranscriptsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTranscriptsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'transcripts': ((json['transcripts'] as Array<any>).map(TranscriptFromJSON)),
    };
}

export function ListTranscriptsResponseContentToJSON(value?: ListTranscriptsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'transcripts': ((value.transcripts as Array<any>).map(TranscriptToJSON)),
    };
}

