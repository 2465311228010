/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QAAnalyticsResults } from './QAAnalyticsResults';
import {
    QAAnalyticsResultsFromJSON,
    QAAnalyticsResultsFromJSONTyped,
    QAAnalyticsResultsToJSON,
} from './QAAnalyticsResults';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface GetQAAnalyticsWithFiltersResponseContent
 */
export interface GetQAAnalyticsWithFiltersResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof GetQAAnalyticsWithFiltersResponseContent
     */
    nextToken?: string;
    /**
     * 
     * @type {QAAnalyticsResults}
     * @memberof GetQAAnalyticsWithFiltersResponseContent
     */
    analytics: QAAnalyticsResults;
}


/**
 * Check if a given object implements the GetQAAnalyticsWithFiltersResponseContent interface.
 */
export function instanceOfGetQAAnalyticsWithFiltersResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "analytics" in value;

    return isInstance;
}

export function GetQAAnalyticsWithFiltersResponseContentFromJSON(json: any): GetQAAnalyticsWithFiltersResponseContent {
    return GetQAAnalyticsWithFiltersResponseContentFromJSONTyped(json, false);
}

export function GetQAAnalyticsWithFiltersResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetQAAnalyticsWithFiltersResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'analytics': QAAnalyticsResultsFromJSON(json['analytics']),
    };
}

export function GetQAAnalyticsWithFiltersResponseContentToJSON(value?: GetQAAnalyticsWithFiltersResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'analytics': QAAnalyticsResultsToJSON(value.analytics),
    };
}

