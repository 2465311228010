/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';

/**
 * 
 * @export
 * @interface CategoryMap
 */
export interface CategoryMap {
    [key: string]: Category;
}


/**
 * Check if a given object implements the CategoryMap interface.
 */
export function instanceOfCategoryMap(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CategoryMapFromJSON(json: any): CategoryMap {
    return CategoryMapFromJSONTyped(json, false);
}

export function CategoryMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryMap {
    return json;
}

export function CategoryMapToJSON(value?: CategoryMap | null): any {
    return value;
}

