/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReTryContactActionResponseContent
 */
export interface ReTryContactActionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof ReTryContactActionResponseContent
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ReTryContactActionResponseContent
     */
    contactImportId?: string;
}


/**
 * Check if a given object implements the ReTryContactActionResponseContent interface.
 */
export function instanceOfReTryContactActionResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReTryContactActionResponseContentFromJSON(json: any): ReTryContactActionResponseContent {
    return ReTryContactActionResponseContentFromJSONTyped(json, false);
}

export function ReTryContactActionResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReTryContactActionResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'contactImportId': !exists(json, 'contactImportId') ? undefined : json['contactImportId'],
    };
}

export function ReTryContactActionResponseContentToJSON(value?: ReTryContactActionResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'contactImportId': value.contactImportId,
    };
}

