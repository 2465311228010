/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallingIdentity } from './CallingIdentity';
import {
    CallingIdentityFromJSON,
    CallingIdentityFromJSONTyped,
    CallingIdentityToJSON,
} from './CallingIdentity';
import type { ContactSynopsisElement } from './ContactSynopsisElement';
import {
    ContactSynopsisElementFromJSON,
    ContactSynopsisElementFromJSONTyped,
    ContactSynopsisElementToJSON,
} from './ContactSynopsisElement';
import type { SynopsisEvaluationRating } from './SynopsisEvaluationRating';
import {
    SynopsisEvaluationRatingFromJSON,
    SynopsisEvaluationRatingFromJSONTyped,
    SynopsisEvaluationRatingToJSON,
} from './SynopsisEvaluationRating';

/**
 * A human evaluation of a contact summary
 * @export
 * @interface SynopsisEvaluation
 */
export interface SynopsisEvaluation {
    /**
     * A user-editable synopsis
     * @type {Array<ContactSynopsisElement>}
     * @memberof SynopsisEvaluation
     */
    synopsis?: Array<ContactSynopsisElement>;
    /**
     * 
     * @type {SynopsisEvaluationRating}
     * @memberof SynopsisEvaluation
     */
    rating?: SynopsisEvaluationRating;
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof SynopsisEvaluation
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof SynopsisEvaluation
     */
    updatedAt: number;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof SynopsisEvaluation
     */
    createdBy: CallingIdentity;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof SynopsisEvaluation
     */
    updatedBy: CallingIdentity;
}


/**
 * Check if a given object implements the SynopsisEvaluation interface.
 */
export function instanceOfSynopsisEvaluation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "updatedBy" in value;

    return isInstance;
}

export function SynopsisEvaluationFromJSON(json: any): SynopsisEvaluation {
    return SynopsisEvaluationFromJSONTyped(json, false);
}

export function SynopsisEvaluationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SynopsisEvaluation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'synopsis': !exists(json, 'synopsis') ? undefined : ((json['synopsis'] as Array<any>).map(ContactSynopsisElementFromJSON)),
        'rating': !exists(json, 'rating') ? undefined : SynopsisEvaluationRatingFromJSON(json['rating']),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'createdBy': CallingIdentityFromJSON(json['createdBy']),
        'updatedBy': CallingIdentityFromJSON(json['updatedBy']),
    };
}

export function SynopsisEvaluationToJSON(value?: SynopsisEvaluation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'synopsis': value.synopsis === undefined ? undefined : ((value.synopsis as Array<any>).map(ContactSynopsisElementToJSON)),
        'rating': SynopsisEvaluationRatingToJSON(value.rating),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'createdBy': CallingIdentityToJSON(value.createdBy),
        'updatedBy': CallingIdentityToJSON(value.updatedBy),
    };
}

