/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";

export interface NotificationContextProviderProps {
  readonly children?: ReactNode;
}

export interface Notification {
  readonly id: string;
  readonly header: ReactNode;
  readonly content: ReactNode;
  // following type copied from @cloudscape-design/components/flashbar > FlashbarProps.Type;
  readonly type: "success" | "warning" | "info" | "error" | "in-progress";
}

export interface INotificationContext {
  readonly notifications: Notification[];
  readonly setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
}

const NotificationContext = createContext<INotificationContext>({} as any);

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider: FC<NotificationContextProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // TODO: needs lots of love to get working again...
  // TODO: look at `if (prev.some((n) => n.header === header && n.content === content))`!

  const [displayed, setDisplayed] = useState<string[]>([]);
  const [dismissed, setDismissed] = useState<string[]>([]);
  const [active, setActive] = useState<Notification[]>([]);

  useEffect(() => {
    setActive(notifications.filter((notification) => !dismissed.includes(notification.id)));
  }, [dismissed, notifications]);

  useEffect(() => {
    // just warn the dev a duplicate notification has come through.
    setDisplayed(
      notifications
        .filter((notification) => {
          if (displayed.includes(notification.id)) {
            console.warn("This notification has already been displayed:", notification);
            return false;
          }
          return true;
        })
        .map((n) => n.id),
    );
  }, [notifications]);

  const onDismiss = useCallback((notificationId: string) => {
    if (dismissed.find((nId) => nId === notificationId)) {
      // console.log("already dismissed", notificationId);
      return;
    }
    // console.log("dismissing", notificationId);
    setDismissed((p) => [...p, notificationId]);
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, setNotifications }}>
      <Flashbar displayed={active} onDismiss={onDismiss} />
      {children}
    </NotificationContext.Provider>
  );
};

const Flashbar = ({ displayed, onDismiss }: { displayed: Notification[]; onDismiss: (n: string) => void }) => {
  if (displayed.length >= 0) return null; // itentionally disabled.
  return (
    <>
      {displayed.map((n) => {
        return (
          <div key={n.id}>
            {n.id}:{n.content}
            <button onClick={() => onDismiss(n.id)}>Dismiss</button>
          </div>
        );
        // return {
        //   type: "info",
        //   dismissible: true,
        //   dismissLabel: "Dismiss message",
        //   // onDismiss: () => setDisplayed([]),
        //   content: (
        //     <>
        //       This is an info flash message. It contains {/* <Link color="inverted" href="#"> */}a link to another page
        //       {/* </Link> */}.
        //     </>
        //   ),
        //   id: "message_1",
        // };
      })}
    </>
  );
};
