/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryMap } from './CategoryMap';
import {
    CategoryMapFromJSON,
    CategoryMapFromJSONTyped,
    CategoryMapToJSON,
} from './CategoryMap';

/**
 * 
 * @export
 * @interface ListAnalyticsWithFiltersResponseContent
 */
export interface ListAnalyticsWithFiltersResponseContent {
    /**
     * 
     * @type {CategoryMap}
     * @memberof ListAnalyticsWithFiltersResponseContent
     */
    analytics: CategoryMap;
}


/**
 * Check if a given object implements the ListAnalyticsWithFiltersResponseContent interface.
 */
export function instanceOfListAnalyticsWithFiltersResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "analytics" in value;

    return isInstance;
}

export function ListAnalyticsWithFiltersResponseContentFromJSON(json: any): ListAnalyticsWithFiltersResponseContent {
    return ListAnalyticsWithFiltersResponseContentFromJSONTyped(json, false);
}

export function ListAnalyticsWithFiltersResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAnalyticsWithFiltersResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'analytics': CategoryMapFromJSON(json['analytics']),
    };
}

export function ListAnalyticsWithFiltersResponseContentToJSON(value?: ListAnalyticsWithFiltersResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'analytics': CategoryMapToJSON(value.analytics),
    };
}

