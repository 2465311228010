/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartEndpointResponseContent
 */
export interface StartEndpointResponseContent {
    /**
     * 
     * @type {string}
     * @memberof StartEndpointResponseContent
     */
    message?: string;
}


/**
 * Check if a given object implements the StartEndpointResponseContent interface.
 */
export function instanceOfStartEndpointResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StartEndpointResponseContentFromJSON(json: any): StartEndpointResponseContent {
    return StartEndpointResponseContentFromJSONTyped(json, false);
}

export function StartEndpointResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartEndpointResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function StartEndpointResponseContentToJSON(value?: StartEndpointResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}

