/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallingIdentity } from './CallingIdentity';
import {
    CallingIdentityFromJSON,
    CallingIdentityFromJSONTyped,
    CallingIdentityToJSON,
} from './CallingIdentity';
import type { SynopsisEvaluationRating } from './SynopsisEvaluationRating';
import {
    SynopsisEvaluationRatingFromJSON,
    SynopsisEvaluationRatingFromJSONTyped,
    SynopsisEvaluationRatingToJSON,
} from './SynopsisEvaluationRating';

/**
 * A human evaluation of a prompt question
 * @export
 * @interface PromptEvaluation
 */
export interface PromptEvaluation {
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof PromptEvaluation
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof PromptEvaluation
     */
    updatedAt: number;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof PromptEvaluation
     */
    createdBy: CallingIdentity;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof PromptEvaluation
     */
    updatedBy: CallingIdentity;
    /**
     * 
     * @type {SynopsisEvaluationRating}
     * @memberof PromptEvaluation
     */
    rating?: SynopsisEvaluationRating;
}


/**
 * Check if a given object implements the PromptEvaluation interface.
 */
export function instanceOfPromptEvaluation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "updatedBy" in value;

    return isInstance;
}

export function PromptEvaluationFromJSON(json: any): PromptEvaluation {
    return PromptEvaluationFromJSONTyped(json, false);
}

export function PromptEvaluationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptEvaluation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'createdBy': CallingIdentityFromJSON(json['createdBy']),
        'updatedBy': CallingIdentityFromJSON(json['updatedBy']),
        'rating': !exists(json, 'rating') ? undefined : SynopsisEvaluationRatingFromJSON(json['rating']),
    };
}

export function PromptEvaluationToJSON(value?: PromptEvaluation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'createdBy': CallingIdentityToJSON(value.createdBy),
        'updatedBy': CallingIdentityToJSON(value.updatedBy),
        'rating': SynopsisEvaluationRatingToJSON(value.rating),
    };
}

