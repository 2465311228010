/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactSynopsisElement } from './ContactSynopsisElement';
import {
    ContactSynopsisElementFromJSON,
    ContactSynopsisElementFromJSONTyped,
    ContactSynopsisElementToJSON,
} from './ContactSynopsisElement';
import type { SummaryStatus } from './SummaryStatus';
import {
    SummaryStatusFromJSON,
    SummaryStatusFromJSONTyped,
    SummaryStatusToJSON,
} from './SummaryStatus';
import type { SynopsisEvaluation } from './SynopsisEvaluation';
import {
    SynopsisEvaluationFromJSON,
    SynopsisEvaluationFromJSONTyped,
    SynopsisEvaluationToJSON,
} from './SynopsisEvaluation';

/**
 * Summary of a contact
 * @export
 * @interface TestPromptForContactResponseContent
 */
export interface TestPromptForContactResponseContent {
    /**
     * The prompt that generated this summary
     * @type {string}
     * @memberof TestPromptForContactResponseContent
     */
    promptRevisionId?: string;
    /**
     * A paragraph synopsis of the call (available if summary status was success)
     * @type {Array<ContactSynopsisElement>}
     * @memberof TestPromptForContactResponseContent
     */
    synopsis?: Array<ContactSynopsisElement>;
    /**
     * 
     * @type {SynopsisEvaluation}
     * @memberof TestPromptForContactResponseContent
     */
    synopsisEvaluation?: SynopsisEvaluation;
    /**
     * 
     * @type {SummaryStatus}
     * @memberof TestPromptForContactResponseContent
     */
    status: SummaryStatus;
    /**
     * Details about the summary status (the error message if summary computation failed)
     * @type {string}
     * @memberof TestPromptForContactResponseContent
     */
    statusDetails?: string;
    /**
     * 
     * @type {number}
     * @memberof TestPromptForContactResponseContent
     */
    complianceTotalScore?: number;
    /**
     * 
     * @type {number}
     * @memberof TestPromptForContactResponseContent
     */
    softSkillsTotalScore?: number;
}


/**
 * Check if a given object implements the TestPromptForContactResponseContent interface.
 */
export function instanceOfTestPromptForContactResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TestPromptForContactResponseContentFromJSON(json: any): TestPromptForContactResponseContent {
    return TestPromptForContactResponseContentFromJSONTyped(json, false);
}

export function TestPromptForContactResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestPromptForContactResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promptRevisionId': !exists(json, 'promptRevisionId') ? undefined : json['promptRevisionId'],
        'synopsis': !exists(json, 'synopsis') ? undefined : ((json['synopsis'] as Array<any>).map(ContactSynopsisElementFromJSON)),
        'synopsisEvaluation': !exists(json, 'synopsisEvaluation') ? undefined : SynopsisEvaluationFromJSON(json['synopsisEvaluation']),
        'status': SummaryStatusFromJSON(json['status']),
        'statusDetails': !exists(json, 'statusDetails') ? undefined : json['statusDetails'],
        'complianceTotalScore': !exists(json, 'complianceTotalScore') ? undefined : json['complianceTotalScore'],
        'softSkillsTotalScore': !exists(json, 'softSkillsTotalScore') ? undefined : json['softSkillsTotalScore'],
    };
}

export function TestPromptForContactResponseContentToJSON(value?: TestPromptForContactResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promptRevisionId': value.promptRevisionId,
        'synopsis': value.synopsis === undefined ? undefined : ((value.synopsis as Array<any>).map(ContactSynopsisElementToJSON)),
        'synopsisEvaluation': SynopsisEvaluationToJSON(value.synopsisEvaluation),
        'status': SummaryStatusToJSON(value.status),
        'statusDetails': value.statusDetails,
        'complianceTotalScore': value.complianceTotalScore,
        'softSkillsTotalScore': value.softSkillsTotalScore,
    };
}

