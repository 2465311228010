/**********************************************************************************************************************
 *  Copyright TRUSST AI PTY LTD. All Rights Reserved.                                                                 *
 *                                                                                                                    *
 *  Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except in compliance        *
 *  with the "LICENSE" file accompanying this file. This file is distributed on an "AS IS" BASIS,                     *
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.                                                 *
 *                                                                                                                    *
 *  See the "License" file for the specific language governing permissions and limitations under the License and      *
 *  limitations under the License.                                                                                    *
 **********************************************************************************************************************/

import {
  Activity,
  Bot,
  BrainCircuit,
  CircleHelp,
  ListTodo,
  ListTree,
  PhoneCall,
  Repeat,
  Settings,
  Swords,
  User,
} from "lucide-react";
import { type MouseEventHandler } from "react";
import { Link } from "react-router-dom";

import SideBarButton from "./SideBarButton";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { cn } from "../../lib/utils";

interface SideBarProps {
  className: string;
  isLightMode: boolean;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLElement>;
  width?: number;
}

const supportLink = "https://docs.trusst.ai/product-guides/user-guide";

const SideBar = ({ className, isLightMode, onClick, onMouseEnter, onMouseLeave, width }: SideBarProps) => {
  // TODO delete: isQAEnabled / is assess enabled!
  const [devMode] = useLocalStorage("dev-mode", false);
  return (
    <div
      className={cn("rounded-md p-1 overflow-hidden border bg-card", className)}
      style={{
        width,
        transition: "width 0.2s ease-in-out, box-shadow 0.2s linear",
        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
      }}
      {...{ onClick, onMouseEnter, onMouseLeave }}
    >
      <div className="h-[calc(100vh-150px)]">
        <Link to="/" className="block truncate z-30 m-2" onClick={onClick} style={{ width: 120 }}>
          <img
            src={isLightMode ? "/TrusstAiLogoLight.png" : "/TrusstAiLogoDark.png"}
            width={120}
            alt="TrusstGPT logo"
          />
        </Link>
        <SideBarButton to={"/analytics"} icon={<Activity />} text="Analytics" />
        <SideBarButton to={"/contacts"} icon={<PhoneCall />} text="Real-Time Contacts" />
        <SideBarButton to={"/imports"} icon={<ListTodo />} text="Contact Import Jobs" />
        <SideBarButton to={"/QA-analytics"} icon={<Repeat />} text="Evaluation" />
        <SideBarButton to={"/QA-prompts"} icon={<ListTree />} text="Prompt Revisions" />
        {devMode && (
          <>
            <SideBarButton to={"/trussted-agents"} icon={<Bot />} text="Trussted Agents" />
            <SideBarButton to={"/data-dialog"} icon={<BrainCircuit />} text="Data Dialog" />
            <SideBarButton to={"/dev-playground"} icon={<Swords />} text="Dev Playground" />
          </>
        )}
      </div>
      <div
      // second block is docked bottom of screen:
      >
        <SideBarButton strongBackground to={supportLink} icon={<CircleHelp />} text="Support" />
        <SideBarButton strongBackground to={"/settings"} icon={<Settings />} text="Settings" />
        <SideBarButton strongBackground to={"/profile"} icon={<User />} text="My Profile" />
      </div>
    </div>
  );
};

export default SideBar;
