/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgentTool
 */
export interface AgentTool {
    /**
     * 
     * @type {string}
     * @memberof AgentTool
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AgentTool
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AgentTool
     */
    inputSchema: string;
}


/**
 * Check if a given object implements the AgentTool interface.
 */
export function instanceOfAgentTool(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "inputSchema" in value;

    return isInstance;
}

export function AgentToolFromJSON(json: any): AgentTool {
    return AgentToolFromJSONTyped(json, false);
}

export function AgentToolFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentTool {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'inputSchema': json['inputSchema'],
    };
}

export function AgentToolToJSON(value?: AgentTool | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'inputSchema': value.inputSchema,
    };
}

