/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category2 } from './Category2';
import {
    Category2FromJSON,
    Category2FromJSONTyped,
    Category2ToJSON,
} from './Category2';

/**
 * 
 * @export
 * @interface Category2Map
 */
export interface Category2Map {
    [key: string]: Category2;
}


/**
 * Check if a given object implements the Category2Map interface.
 */
export function instanceOfCategory2Map(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Category2MapFromJSON(json: any): Category2Map {
    return Category2MapFromJSONTyped(json, false);
}

export function Category2MapFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category2Map {
    return json;
}

export function Category2MapToJSON(value?: Category2Map | null): any {
    return value;
}

