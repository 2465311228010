/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryMap } from './CategoryMap';
import {
    CategoryMapFromJSON,
    CategoryMapFromJSONTyped,
    CategoryMapToJSON,
} from './CategoryMap';

/**
 * 
 * @export
 * @interface ListAnalyticsResponseContent
 */
export interface ListAnalyticsResponseContent {
    /**
     * 
     * @type {CategoryMap}
     * @memberof ListAnalyticsResponseContent
     */
    analytics: CategoryMap;
}


/**
 * Check if a given object implements the ListAnalyticsResponseContent interface.
 */
export function instanceOfListAnalyticsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "analytics" in value;

    return isInstance;
}

export function ListAnalyticsResponseContentFromJSON(json: any): ListAnalyticsResponseContent {
    return ListAnalyticsResponseContentFromJSONTyped(json, false);
}

export function ListAnalyticsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAnalyticsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'analytics': CategoryMapFromJSON(json['analytics']),
    };
}

export function ListAnalyticsResponseContentToJSON(value?: ListAnalyticsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'analytics': CategoryMapToJSON(value.analytics),
    };
}

