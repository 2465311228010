/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type AcceptedPromptTypes =
  'extraction_binary' | 
  'extraction_single_label' | 
  'extraction_rating'


export function AcceptedPromptTypesFromJSON(json: any): AcceptedPromptTypes {
    return AcceptedPromptTypesFromJSONTyped(json, false);
}

export function AcceptedPromptTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptedPromptTypes {
    return json as AcceptedPromptTypes;
}

export function AcceptedPromptTypesToJSON(value?: AcceptedPromptTypes | null): any {
    return value as any;
}

