/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactImportAggrQuestionScores
 */
export interface ContactImportAggrQuestionScores {
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrQuestionScores
     */
    contactImportId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrQuestionScores
     */
    promptGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrQuestionScores
     */
    questionKey: string;
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrQuestionScores
     */
    questionId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactImportAggrQuestionScores
     */
    isParent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactImportAggrQuestionScores
     */
    isChild: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactImportAggrQuestionScores
     */
    scorePercentage: string;
}


/**
 * Check if a given object implements the ContactImportAggrQuestionScores interface.
 */
export function instanceOfContactImportAggrQuestionScores(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactImportId" in value;
    isInstance = isInstance && "promptGroupName" in value;
    isInstance = isInstance && "questionKey" in value;
    isInstance = isInstance && "questionId" in value;
    isInstance = isInstance && "isParent" in value;
    isInstance = isInstance && "isChild" in value;
    isInstance = isInstance && "scorePercentage" in value;

    return isInstance;
}

export function ContactImportAggrQuestionScoresFromJSON(json: any): ContactImportAggrQuestionScores {
    return ContactImportAggrQuestionScoresFromJSONTyped(json, false);
}

export function ContactImportAggrQuestionScoresFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactImportAggrQuestionScores {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactImportId': json['contactImportId'],
        'promptGroupName': json['promptGroupName'],
        'questionKey': json['questionKey'],
        'questionId': json['questionId'],
        'isParent': json['isParent'],
        'isChild': json['isChild'],
        'scorePercentage': json['scorePercentage'],
    };
}

export function ContactImportAggrQuestionScoresToJSON(value?: ContactImportAggrQuestionScores | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactImportId': value.contactImportId,
        'promptGroupName': value.promptGroupName,
        'questionKey': value.questionKey,
        'questionId': value.questionId,
        'isParent': value.isParent,
        'isChild': value.isChild,
        'scorePercentage': value.scorePercentage,
    };
}

