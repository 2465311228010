/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of a contact import job
 * @export
 * @enum {string}
 */
export type ContactImportStatus =
  'INITIALISING' | 
  'STARTED' | 
  'IN_PROGRESS' | 
  'PARTIAL_SUCCESS' | 
  'SUCCESS' | 
  'FAILURE'


export function ContactImportStatusFromJSON(json: any): ContactImportStatus {
    return ContactImportStatusFromJSONTyped(json, false);
}

export function ContactImportStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactImportStatus {
    return json as ContactImportStatus;
}

export function ContactImportStatusToJSON(value?: ContactImportStatus | null): any {
    return value as any;
}

