/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User specified rating for a generated synopsis
 * @export
 * @enum {string}
 */
export type SynopsisEvaluationRating =
  'GOOD' | 
  'BAD'


export function SynopsisEvaluationRatingFromJSON(json: any): SynopsisEvaluationRating {
    return SynopsisEvaluationRatingFromJSONTyped(json, false);
}

export function SynopsisEvaluationRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): SynopsisEvaluationRating {
    return json as SynopsisEvaluationRating;
}

export function SynopsisEvaluationRatingToJSON(value?: SynopsisEvaluationRating | null): any {
    return value as any;
}

