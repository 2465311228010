/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Metrics for ratings for this prompt across all contacts.
 * Counts are unique to the user and the contact.
 * The thumbs up/down shown in the UI is the rating stored in dynamodb, which is the latest rating by any user,
 * whereas metrics track all unique user ratings for a contact.
 * @export
 * @interface QueryPromptRatingMetricsResponseContent
 */
export interface QueryPromptRatingMetricsResponseContent {
    /**
     * Count of "GOOD" ratings
     * @type {number}
     * @memberof QueryPromptRatingMetricsResponseContent
     */
    good: number;
    /**
     * Count of "BAD" ratings
     * @type {number}
     * @memberof QueryPromptRatingMetricsResponseContent
     */
    bad: number;
}


/**
 * Check if a given object implements the QueryPromptRatingMetricsResponseContent interface.
 */
export function instanceOfQueryPromptRatingMetricsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "good" in value;
    isInstance = isInstance && "bad" in value;

    return isInstance;
}

export function QueryPromptRatingMetricsResponseContentFromJSON(json: any): QueryPromptRatingMetricsResponseContent {
    return QueryPromptRatingMetricsResponseContentFromJSONTyped(json, false);
}

export function QueryPromptRatingMetricsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryPromptRatingMetricsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'good': json['good'],
        'bad': json['bad'],
    };
}

export function QueryPromptRatingMetricsResponseContentToJSON(value?: QueryPromptRatingMetricsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'good': value.good,
        'bad': value.bad,
    };
}

