/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileS3Location
 */
export interface FileS3Location {
    /**
     * 
     * @type {string}
     * @memberof FileS3Location
     */
    bucket: string;
    /**
     * 
     * @type {string}
     * @memberof FileS3Location
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof FileS3Location
     */
    fileName: string;
}


/**
 * Check if a given object implements the FileS3Location interface.
 */
export function instanceOfFileS3Location(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bucket" in value;
    isInstance = isInstance && "fileName" in value;

    return isInstance;
}

export function FileS3LocationFromJSON(json: any): FileS3Location {
    return FileS3LocationFromJSONTyped(json, false);
}

export function FileS3LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileS3Location {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bucket': json['bucket'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'fileName': json['fileName'],
    };
}

export function FileS3LocationToJSON(value?: FileS3Location | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bucket': value.bucket,
        'key': value.key,
        'fileName': value.fileName,
    };
}

