/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAssessEnabledResponseContent
 */
export interface GetAssessEnabledResponseContent {
    /**
     * 
     * @type {string}
     * @memberof GetAssessEnabledResponseContent
     */
    isQAEnabled?: string;
}


/**
 * Check if a given object implements the GetAssessEnabledResponseContent interface.
 */
export function instanceOfGetAssessEnabledResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAssessEnabledResponseContentFromJSON(json: any): GetAssessEnabledResponseContent {
    return GetAssessEnabledResponseContentFromJSONTyped(json, false);
}

export function GetAssessEnabledResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAssessEnabledResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isQAEnabled': !exists(json, 'isQAEnabled') ? undefined : json['isQAEnabled'],
    };
}

export function GetAssessEnabledResponseContentToJSON(value?: GetAssessEnabledResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isQAEnabled': value.isQAEnabled,
    };
}

