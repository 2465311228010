/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallingIdentity } from './CallingIdentity';
import {
    CallingIdentityFromJSON,
    CallingIdentityFromJSONTyped,
    CallingIdentityToJSON,
} from './CallingIdentity';

/**
 * Extends structures with details about when the resource was created/updated
 * @export
 * @interface PromptComment
 */
export interface PromptComment {
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof PromptComment
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof PromptComment
     */
    updatedAt: number;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof PromptComment
     */
    createdBy: CallingIdentity;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof PromptComment
     */
    updatedBy: CallingIdentity;
    /**
     * 
     * @type {string}
     * @memberof PromptComment
     */
    comment?: string;
}


/**
 * Check if a given object implements the PromptComment interface.
 */
export function instanceOfPromptComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "updatedBy" in value;

    return isInstance;
}

export function PromptCommentFromJSON(json: any): PromptComment {
    return PromptCommentFromJSONTyped(json, false);
}

export function PromptCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'createdBy': CallingIdentityFromJSON(json['createdBy']),
        'updatedBy': CallingIdentityFromJSON(json['updatedBy']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PromptCommentToJSON(value?: PromptComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'createdBy': CallingIdentityToJSON(value.createdBy),
        'updatedBy': CallingIdentityToJSON(value.updatedBy),
        'comment': value.comment,
    };
}

