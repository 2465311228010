/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PromptRatingAggregation
 */
export interface PromptRatingAggregation {
    /**
     * 
     * @type {string}
     * @memberof PromptRatingAggregation
     */
    questionId: string;
    /**
     * 
     * @type {number}
     * @memberof PromptRatingAggregation
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PromptRatingAggregation
     */
    goodPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof PromptRatingAggregation
     */
    badPercentage: number;
}


/**
 * Check if a given object implements the PromptRatingAggregation interface.
 */
export function instanceOfPromptRatingAggregation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "questionId" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "goodPercentage" in value;
    isInstance = isInstance && "badPercentage" in value;

    return isInstance;
}

export function PromptRatingAggregationFromJSON(json: any): PromptRatingAggregation {
    return PromptRatingAggregationFromJSONTyped(json, false);
}

export function PromptRatingAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptRatingAggregation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': json['questionId'],
        'total': json['total'],
        'goodPercentage': json['goodPercentage'],
        'badPercentage': json['badPercentage'],
    };
}

export function PromptRatingAggregationToJSON(value?: PromptRatingAggregation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'total': value.total,
        'goodPercentage': value.goodPercentage,
        'badPercentage': value.badPercentage,
    };
}

