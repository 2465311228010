/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromptComment } from './PromptComment';
import {
    PromptCommentFromJSON,
    PromptCommentFromJSONTyped,
    PromptCommentToJSON,
} from './PromptComment';
import type { PromptEvaluation } from './PromptEvaluation';
import {
    PromptEvaluationFromJSON,
    PromptEvaluationFromJSONTyped,
    PromptEvaluationToJSON,
} from './PromptEvaluation';
import type { PromptQuestion } from './PromptQuestion';
import {
    PromptQuestionFromJSON,
    PromptQuestionFromJSONTyped,
    PromptQuestionToJSON,
} from './PromptQuestion';

/**
 * An element of a synopsis
 * @export
 * @interface ContactSynopsisElement
 */
export interface ContactSynopsisElement {
    /**
     * Section title for the synopsis
     * @type {string}
     * @memberof ContactSynopsisElement
     */
    key: string;
    /**
     * The content of this section of the synopsis
     * @type {string}
     * @memberof ContactSynopsisElement
     */
    response: string;
    /**
     * 
     * @type {string}
     * @memberof ContactSynopsisElement
     */
    score?: string;
    /**
     * 
     * @type {PromptEvaluation}
     * @memberof ContactSynopsisElement
     */
    rating?: PromptEvaluation;
    /**
     * 
     * @type {PromptComment}
     * @memberof ContactSynopsisElement
     */
    comment?: PromptComment;
    /**
     * 
     * @type {PromptQuestion}
     * @memberof ContactSynopsisElement
     */
    question?: PromptQuestion;
    /**
     * 
     * @type {string}
     * @memberof ContactSynopsisElement
     */
    promptGroupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSynopsisElement
     */
    isSectionAutoFailed?: boolean;
}


/**
 * Check if a given object implements the ContactSynopsisElement interface.
 */
export function instanceOfContactSynopsisElement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "response" in value;

    return isInstance;
}

export function ContactSynopsisElementFromJSON(json: any): ContactSynopsisElement {
    return ContactSynopsisElementFromJSONTyped(json, false);
}

export function ContactSynopsisElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSynopsisElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'response': json['response'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'rating': !exists(json, 'rating') ? undefined : PromptEvaluationFromJSON(json['rating']),
        'comment': !exists(json, 'comment') ? undefined : PromptCommentFromJSON(json['comment']),
        'question': !exists(json, 'question') ? undefined : PromptQuestionFromJSON(json['question']),
        'promptGroupName': !exists(json, 'promptGroupName') ? undefined : json['promptGroupName'],
        'isSectionAutoFailed': !exists(json, 'isSectionAutoFailed') ? undefined : json['isSectionAutoFailed'],
    };
}

export function ContactSynopsisElementToJSON(value?: ContactSynopsisElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'response': value.response,
        'score': value.score,
        'rating': PromptEvaluationToJSON(value.rating),
        'comment': PromptCommentToJSON(value.comment),
        'question': PromptQuestionToJSON(value.question),
        'promptGroupName': value.promptGroupName,
        'isSectionAutoFailed': value.isSectionAutoFailed,
    };
}

