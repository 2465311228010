/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallingIdentity } from './CallingIdentity';
import {
    CallingIdentityFromJSON,
    CallingIdentityFromJSONTyped,
    CallingIdentityToJSON,
} from './CallingIdentity';
import type { PromptGroups } from './PromptGroups';
import {
    PromptGroupsFromJSON,
    PromptGroupsFromJSONTyped,
    PromptGroupsToJSON,
} from './PromptGroups';

/**
 * A revision of a prompt for contact summarisation
 * @export
 * @interface GetPublishedPromptRevisionResponseContent
 */
export interface GetPublishedPromptRevisionResponseContent {
    /**
     * Name of the prompt revision
     * @type {string}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    name: string;
    /**
     * 
     * @type {PromptGroups}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    promptGroups: PromptGroups;
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    updatedAt: number;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    createdBy: CallingIdentity;
    /**
     * 
     * @type {CallingIdentity}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    updatedBy: CallingIdentity;
    /**
     * Identifier for the prompt revision
     * @type {string}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    promptRevisionId: string;
    /**
     * Original prompt ID used when setting the ID as the published prompt
     * @type {string}
     * @memberof GetPublishedPromptRevisionResponseContent
     */
    originalPromptRevisionId?: string;
}


/**
 * Check if a given object implements the GetPublishedPromptRevisionResponseContent interface.
 */
export function instanceOfGetPublishedPromptRevisionResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "promptGroups" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "updatedBy" in value;
    isInstance = isInstance && "promptRevisionId" in value;

    return isInstance;
}

export function GetPublishedPromptRevisionResponseContentFromJSON(json: any): GetPublishedPromptRevisionResponseContent {
    return GetPublishedPromptRevisionResponseContentFromJSONTyped(json, false);
}

export function GetPublishedPromptRevisionResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPublishedPromptRevisionResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'promptGroups': PromptGroupsFromJSON(json['promptGroups']),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'createdBy': CallingIdentityFromJSON(json['createdBy']),
        'updatedBy': CallingIdentityFromJSON(json['updatedBy']),
        'promptRevisionId': json['promptRevisionId'],
        'originalPromptRevisionId': !exists(json, 'originalPromptRevisionId') ? undefined : json['originalPromptRevisionId'],
    };
}

export function GetPublishedPromptRevisionResponseContentToJSON(value?: GetPublishedPromptRevisionResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'promptGroups': PromptGroupsToJSON(value.promptGroups),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'createdBy': CallingIdentityToJSON(value.createdBy),
        'updatedBy': CallingIdentityToJSON(value.updatedBy),
        'promptRevisionId': value.promptRevisionId,
        'originalPromptRevisionId': value.originalPromptRevisionId,
    };
}

