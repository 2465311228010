/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AcceptedPromptTypes } from './AcceptedPromptTypes';
import {
    AcceptedPromptTypesFromJSON,
    AcceptedPromptTypesFromJSONTyped,
    AcceptedPromptTypesToJSON,
} from './AcceptedPromptTypes';
import type { VisualisationType } from './VisualisationType';
import {
    VisualisationTypeFromJSON,
    VisualisationTypeFromJSONTyped,
    VisualisationTypeToJSON,
} from './VisualisationType';

/**
 * 
 * @export
 * @interface PromptVisualisation
 */
export interface PromptVisualisation {
    /**
     * 
     * @type {string}
     * @memberof PromptVisualisation
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PromptVisualisation
     */
    questionId: string;
    /**
     * 
     * @type {AcceptedPromptTypes}
     * @memberof PromptVisualisation
     */
    questionType: AcceptedPromptTypes;
    /**
     * 
     * @type {VisualisationType}
     * @memberof PromptVisualisation
     */
    visualisationType: VisualisationType;
    /**
     * 
     * @type {string}
     * @memberof PromptVisualisation
     */
    tooltip?: string;
}


/**
 * Check if a given object implements the PromptVisualisation interface.
 */
export function instanceOfPromptVisualisation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "questionId" in value;
    isInstance = isInstance && "questionType" in value;
    isInstance = isInstance && "visualisationType" in value;

    return isInstance;
}

export function PromptVisualisationFromJSON(json: any): PromptVisualisation {
    return PromptVisualisationFromJSONTyped(json, false);
}

export function PromptVisualisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptVisualisation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'questionId': json['questionId'],
        'questionType': AcceptedPromptTypesFromJSON(json['questionType']),
        'visualisationType': VisualisationTypeFromJSON(json['visualisationType']),
        'tooltip': !exists(json, 'tooltip') ? undefined : json['tooltip'],
    };
}

export function PromptVisualisationToJSON(value?: PromptVisualisation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'questionId': value.questionId,
        'questionType': AcceptedPromptTypesToJSON(value.questionType),
        'visualisationType': VisualisationTypeToJSON(value.visualisationType),
        'tooltip': value.tooltip,
    };
}

