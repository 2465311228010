/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Contact participant
 * @export
 * @enum {string}
 */
export type Participant =
  'CUSTOMER' | 
  'AGENT'


export function ParticipantFromJSON(json: any): Participant {
    return ParticipantFromJSONTyped(json, false);
}

export function ParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Participant {
    return json as Participant;
}

export function ParticipantToJSON(value?: Participant | null): any {
    return value as any;
}

