/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromptType } from './PromptType';
import {
    PromptTypeFromJSON,
    PromptTypeFromJSONTyped,
    PromptTypeToJSON,
} from './PromptType';
import type { Properties } from './Properties';
import {
    PropertiesFromJSON,
    PropertiesFromJSONTyped,
    PropertiesToJSON,
} from './Properties';

/**
 * A question to ask
 * @export
 * @interface PromptQuestion
 */
export interface PromptQuestion {
    /**
     * Question key
     * @type {string}
     * @memberof PromptQuestion
     */
    key: string;
    /**
     * ID
     * @type {string}
     * @memberof PromptQuestion
     */
    id: string;
    /**
     * The question to ask in the prompt
     * @type {string}
     * @memberof PromptQuestion
     */
    question: string;
    /**
     * 
     * @type {PromptType}
     * @memberof PromptQuestion
     */
    promptType: PromptType;
    /**
     * 
     * @type {Properties}
     * @memberof PromptQuestion
     */
    properties: Properties;
}


/**
 * Check if a given object implements the PromptQuestion interface.
 */
export function instanceOfPromptQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "promptType" in value;
    isInstance = isInstance && "properties" in value;

    return isInstance;
}

export function PromptQuestionFromJSON(json: any): PromptQuestion {
    return PromptQuestionFromJSONTyped(json, false);
}

export function PromptQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'id': json['id'],
        'question': json['question'],
        'promptType': PromptTypeFromJSON(json['promptType']),
        'properties': PropertiesFromJSON(json['properties']),
    };
}

export function PromptQuestionToJSON(value?: PromptQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'id': value.id,
        'question': value.question,
        'promptType': PromptTypeToJSON(value.promptType),
        'properties': PropertiesToJSON(value.properties),
    };
}

