/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of the contact
 * @export
 * @enum {string}
 */
export type ContactStatus =
  'IN_PROGRESS' | 
  'COMPLETED' | 
  'UN_IMPORTED' | 
  'FAILED'


export function ContactStatusFromJSON(json: any): ContactStatus {
    return ContactStatusFromJSONTyped(json, false);
}

export function ContactStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactStatus {
    return json as ContactStatus;
}

export function ContactStatusToJSON(value?: ContactStatus | null): any {
    return value as any;
}

