/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImportedContact } from './ImportedContact';
import {
    ImportedContactFromJSON,
    ImportedContactFromJSONTyped,
    ImportedContactToJSON,
} from './ImportedContact';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListBatchContactImportResultsResponseContent
 */
export interface ListBatchContactImportResultsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListBatchContactImportResultsResponseContent
     */
    nextToken?: string;
    /**
     * List of imported contacts
     * @type {Array<ImportedContact>}
     * @memberof ListBatchContactImportResultsResponseContent
     */
    contacts: Array<ImportedContact>;
    /**
     * 
     * @type {number}
     * @memberof ListBatchContactImportResultsResponseContent
     */
    totalPages?: number;
}


/**
 * Check if a given object implements the ListBatchContactImportResultsResponseContent interface.
 */
export function instanceOfListBatchContactImportResultsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contacts" in value;

    return isInstance;
}

export function ListBatchContactImportResultsResponseContentFromJSON(json: any): ListBatchContactImportResultsResponseContent {
    return ListBatchContactImportResultsResponseContentFromJSONTyped(json, false);
}

export function ListBatchContactImportResultsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListBatchContactImportResultsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'contacts': ((json['contacts'] as Array<any>).map(ImportedContactFromJSON)),
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
    };
}

export function ListBatchContactImportResultsResponseContentToJSON(value?: ListBatchContactImportResultsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'contacts': ((value.contacts as Array<any>).map(ImportedContactToJSON)),
        'totalPages': value.totalPages,
    };
}

